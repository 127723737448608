import { Tooltip, styled } from "@mui/material";
import { CertaintyBarPropsI } from "../../../components/atoms/CertaintyProgressBar";
import { withStyles } from "@mui/styles";

export const ConfidenceLevelContent = styled('div')<CertaintyBarPropsI>(({ colors }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: colors,
  fontSize: 10,
  fontWeight: 700
}))

export const ConfidenceLevelBar = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: 8,
  "& > div": {
    margin: 2
  }
})

export const SessionErrorWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  gap: 12
})

export const GetStartedContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  textAlign: 'center',
  top: 100,
})

export const AceFetchingWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  "& > div": {
    flexDirection: 'row',
    display: 'flex',
    gap: 4,
  }
})

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#29333D",
    padding: "8px 16px",
    borderRadius: 6,
    cursor: 'pointer',
  },
  arrow: {
    color: '#29333D',
    position: 'absolute',
    left: 0,
  },
})(Tooltip);
