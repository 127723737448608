import {
  FolderDriveI,
  locationI
} from '../../../pages/businessDrives/businessDrives.types';
import { ListItem, ListItemI } from './elements';
import { Menu, MenuItem, MenuList } from '@mui/material';
import React, { Fragment } from 'react';
import {
  addFolder,
  deleteFolder,
  moveFolder,
  renameFolder,
  reorderCal
} from '../../../store/actions/folderActions';
import {
  calInfoDelete,
  calInfoRename,
  fetchCalculationInfo
} from '../../../store/actions/calInfoAction';
import {
  changeTypeView,
  fetchTeamDrives,
  fetchTutorial,
  formHelperPanel,
  setWorkspace,
  showHideModalAction
} from '../../../store/appState/global/globalActions';
import {
  checkIndex,
  checkSubscriptionAndDate,
  findRecentCalculation
} from '../../../utils/helpersFunction';
import {
  checkProfileRole,
  getCommunityWorkspaceId,
  getEnterpriseWorkspaceId,
  getPersonalWorkspaceId,
  getUserProfile
} from '../../../utils/storage';
import {
  createNewCalc,
  editCalcInfo,
  makeCopyCal,
  moveCal
} from '../../../store/actions/calculationsActions';
import {
  dataAction,
  encalDataAction
} from '../../../store/actions/encalActions';
import {
  fetchAllCompany,
  isActiveItem,
  isActiveUser
} from '../../../store/actions/userAction';

import { BREADCRUMB_PAGE_TYPE } from '../../../store/breadCrumb/managerNavigation/reducer';
import CalculationsMenu from '../../molecules/calculationsMenu';
import { ListItemText } from '@mui/material';
import { ListItemTextBilling } from '../../molecules/BillingTable/ListItemTextBilling';
import { ReduxState } from '../../../store';
import { ThunkDispatch } from 'redux-thunk';
import Typography from '../../atoms/Typography';
import UserService from '../../../service/UserService';
import { calIdAction } from '../../../store/actions/calInfoAction';
import { clearRecentInputFile } from '../../../store/actions/loggerAction';
import { connect } from 'react-redux';
import { downloadFile } from '../../../utils/fileHandler';
import { fetchInputFileList } from '../../../store/actions/inputFileAction';
import { formModalSetupActionPopUp } from '../../../store/appState/popUp/formModal/formModalAction';
import { getBreadcrumb } from '../../../store/breadCrumb/managerNavigation/action';
import { toastSetupActionPopUp } from '../../../store/appState/popUp/toast/toastAction';
import { writeToast } from '../../../store/actions/toastAction';

interface newFolderI {
  parentId: string;
  title: string;
  type: string;
}

interface renameFolderI {
  id: string;
  title: string;
}

interface moveFolderI {
  folderId: string;
  destinationFolderId: string;
}

// These props will be injected into the wrapped component
interface ConnectedProps {
  handleEncalDataAction: (id: string) => void;
  handleChangeEncalDataAction: (data: any) => void;
  handleCalInfoRename: (calId: string, name: string) => void;
  hanldeCalInfoDelete: (calId: string) => void;
  handleShowHideModalAction: (value: boolean, typeAction: string) => void;
  handleChangeTypeView: (typeView: string) => void;
  handleCalIdAction: (
    calId: string,
    calName: string,
    calInputFiles: number,
    calApproval: number,
    calDescription: string,
    id: string
  ) => void;
  handleWriteToast: (status: boolean, color: string, message: string) => void;
  handleCreateNewCal: (folderId: string, values: any, history: any) => void;
  handleEditCalcInfo: (calId: string, values: any) => void;
  handleMakeCopyCal: (data: any) => void;
  handleMoveCal: (calId: string, folderId: string) => void;
  handleAddFolder: (data: newFolderI) => void;
  handleDeleteFolder: (folderId: string) => void;
  handleMoveFolder: (data: moveFolderI) => void;
  handleRenameFolder: (data: renameFolderI) => void;
  handleModalPopup: (type: string, payload?: any) => void;
  handleSetWorkspace: (payload: string) => void;
  handleGetBreadcumbs: (id: string, type?: string) => void;
  handleGetCalculationInfo: (id: string) => void;
  handleIsActiveItem: (payload: any) => void;
  handleIsActiveUser: (payload: any) => void;
  handleFetchAllCompanies: () => void;
  handleFetchTutorial: (payload: any) => void;
  handleFormHelperPanel: (isOpen: boolean, type: string, payload?: any) => void;
  handleFetchInputFileList: (calculationId: string) => void;
  handleClearRecentInputFile: () => void;
  handleToastSetupActionPopUp: (type: string, label: string) => void;
  handleDispatch: (payload: any) => void;
  handleReorderCal: (
    folderId: string,
    draggableId: string,
    sourceId: any
  ) => void;
  handleFetchTeamDrives: (id?: string) => void;
}

export const calculationsNav = <BaseProps extends ConnectedProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  const mapStateToProps = (state: ReduxState) => ({
    logger: state.logger,
    itemTitle: state.logger?.currentInputFile?.title,
    encal: state.encal,
    calInfo: state.calInfo,
    calculations: state.calculations,
    global: state.global,
    login: state.login,
    toastPopUp: state.toastPopUp,
    users: state.users,
    navigation: state.managerNavigation,
    workspace: state.global.workspace,
    sidebar: state.sidebar,
  });

  const mapDispatchToProps = (
    dispatch: ThunkDispatch<
      Record<string, unknown>,
      Record<string, unknown>,
      any
    >
  ): ConnectedProps => {
    return {
      handleEncalDataAction: (id: string) => dispatch(encalDataAction(id)),
      handleChangeEncalDataAction: (data: any) => dispatch(dataAction(data)),
      handleCalInfoRename: (calId: string, name: string) =>
        dispatch(calInfoRename(calId, name)),
      hanldeCalInfoDelete: (calId: string) => dispatch(calInfoDelete(calId)),
      handleShowHideModalAction: (value: boolean, typeAction: string) =>
        dispatch(showHideModalAction(value, typeAction)),
      handleChangeTypeView: (typeView: string) =>
        dispatch(changeTypeView(typeView)),
      handleCalIdAction: (
        calId: string,
        calName: string,
        calInputFiles: number,
        calApproval: number,
        calDescription: string,
        id: string
      ) =>
        dispatch(
          calIdAction(
            calId,
            calName,
            calInputFiles,
            calApproval,
            calDescription,
            id
          )
        ),
      handleWriteToast: (status: boolean, color: string, message: string) =>
        dispatch(writeToast(status, color, message)),
      handleCreateNewCal: (folderId: string, values: any, history: any) =>
        dispatch(createNewCalc(folderId, values, history)),
      handleEditCalcInfo: (calId: string, values: any) =>
        dispatch(editCalcInfo(calId, values)),
      handleMakeCopyCal: (data: any) => dispatch(makeCopyCal(data)),
      handleMoveCal: (calId: string, folderId: string) =>
        dispatch(moveCal(calId, folderId)),
      handleAddFolder: (data: newFolderI) => dispatch(addFolder(data)),
      handleDeleteFolder: (folderId: string) =>
        dispatch(deleteFolder(folderId)),
      handleMoveFolder: (data: moveFolderI) => dispatch(moveFolder(data)),
      handleRenameFolder: (data: renameFolderI) => dispatch(renameFolder(data)),
      handleModalPopup: (type: string, payload?: any) =>
        dispatch(formModalSetupActionPopUp(type, payload)),
      handleSetWorkspace: (payload: string) => dispatch(setWorkspace(payload)),
      handleGetBreadcumbs: (id: string, type?: string) =>
        dispatch(getBreadcrumb(id, type ?? BREADCRUMB_PAGE_TYPE.OTHER)),
      handleGetCalculationInfo: (id: string) =>
        dispatch(fetchCalculationInfo(id)),
      handleIsActiveItem: (payload: any) => dispatch(isActiveUser(payload)),
      handleFetchAllCompanies: () => dispatch(fetchAllCompany()),
      handleIsActiveUser: (payload: any) => dispatch(isActiveItem(payload)),
      handleFetchTutorial: (payload: any) => dispatch(fetchTutorial(payload)),
      handleFormHelperPanel: (isOpen: boolean, type: string, payload?: any) =>
        dispatch(formHelperPanel(isOpen, type, payload)),
      handleFetchInputFileList: (calculationId: string) =>
        dispatch(fetchInputFileList(calculationId)),
      handleClearRecentInputFile: () => dispatch(clearRecentInputFile()),
      handleToastSetupActionPopUp: (type: string, label: string) =>
        dispatch(toastSetupActionPopUp(type, label)),
      handleDispatch: (payload: any) => dispatch(payload),
      handleReorderCal: (
        folderId: string,
        draggableId: string,
        sourceId: Array<any>
      ) => dispatch(reorderCal(folderId, draggableId, sourceId)),
      handleFetchTeamDrives: (id?: string) => dispatch(fetchTeamDrives(id))
    };
  };

  type HocProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
      // extend ConnectedHoc with new loading state props
      // no need to access to logger.isloading to trigger page loading
      loading?: boolean;
      // state active item need to be injected because the modal dialog component need it
      activeItem?: any;
    };

  class Hoc extends React.Component<HocProps> {
    // Enhance component name for debugging and React-Dev-Tools
    static displayName = `calculationsNav(${BaseComponent.name})`;
    // reference to original wrapped component
    static readonly WrappedComponent = BaseComponent;

    state = {
      isMinimize: false,
      isActiveFolder: '',
      selectedTaskIndex: [],
      selectedCalcId: [],
      activeItem: {},
      itemType: '',
      styleView: 'List',
      contextMenu: null,
      contextUpdate: {
        mouseY: 1,
        mouseX: 1
      },
      file: {
        url: '',
        fileName: ''
      },
      openPanel: false,
      isActiveItem: false,
      selectedCommId: 0,
      subscriptionList: [],
      isShowMobileSidebar: false,
    };

    componentDidMount = () => {
      this.props.handleCalIdAction('', '', 0, 0, '', '');
    };

    componentWillReceiveProps(nextProps: any) {
      if (
        this.props.navigation.breadcrumb !== nextProps.navigation.breadcrumb
      ) {
        this.props.navigation.breadcrumb = nextProps.navigation.breadcrumb;
      }
    }

    userRole = getUserProfile();

    isComposer = /COMPOSER/.test(String(this.userRole));
    isRekava = /REKAVA/.test(String(this.userRole));

    handleMinimizeSidebar = () => {
      this.setState({
        isMinimize: !this.state.isMinimize
      });
    };

    handleSelectStyleView = (styleType: string) => {
      this.setState({
        styleView: styleType
      });
    };

    handleActiveFocusFolder = (
      e: MouseEvent,
      i: number,
      calIdH: string,
      item: any,
      id: string
    ) => {
      e.stopPropagation();
      this.setState({ selectedCalcId: [] });
      this.setState({ selectedTaskIndex: [] });
      Promise.all([
        // fetch calc info with latestVersionId for enterprise calculation
        // change latestversionId with calcId for Personal Calculation
        window.location.pathname.includes('enterprise') &&
        this.props.handleGetCalculationInfo(item.lastVersionId),
        this.setState({ isActiveFolder: i }),
        this.setState({ activeItem: item }),
        this.setState({ openPanel: true })
      ])
        .then(() => {
          this.props.handleCalIdAction(calIdH, '', 0, 0, '', id);
          this.setState({
            itemType: ''
          });
        })
        .catch(error => console.error(error));
    };

    handleActiveFocus = (
      e: MouseEvent,
      i: number,
      itemType: string,
      calIdH: string,
      title: string,
      inputFile: number,
      approval: number,
      description: string,
      item: any,
      id: string,
      selectIndex: any,
      selectedIds: any
    ) => {
      e.stopPropagation();
      this.setState({ selectedTaskIndex: selectIndex });
      this.setState({
        selectedCalcId: selectedIds.length > 1 ? selectedIds : [item.id]
      });
      Promise.all([
        // fetch calc info with latestVersionId for enterprise calculation
        // change latestversionId with calcId for Personal Calculation
        window.location.pathname.includes('enterprise') &&
        this.props.handleGetCalculationInfo(item.lastVersionId),
        this.setState({ isActiveFolder: i }),
        this.setState({ activeItem: item }),
        this.setState({ openPanel: true })
      ])
        .then(() => {
          if (itemType === 'CALCULATION') {
            this.props.handleCalIdAction(
              calIdH,
              title,
              inputFile,
              approval,
              description,
              id
            );
            this.setState({
              itemType: itemType
            });
          } else {
            this.props.handleCalIdAction(calIdH, '', 0, 0, '', id);
            this.setState({
              itemType: ''
            });
          }
        })
        .catch(error => console.error(error));
    };

    handleInActiveFocus = () => {
      if (this.state.contextMenu === null) {
        Promise.all([
          this.setState({ isActiveFolder: '' }),
          this.setState({ activeItem: {} }),
          this.setState({ itemType: '' }),
          this.setState({ openPanel: false })
        ])
          .then(() => this.props.handleCalIdAction('', '', 0, 0, '', ''))
          .catch(error => console.error(error));
      }
    };

    handleContextMenu = (
      e: any,
      item: any,
      i: number,
      itemType: string,
      id: string
    ) => {
      e.preventDefault();
      if (checkProfileRole(getUserProfile()) >= 6) {
        return;
      } else {
        Promise.all([
          window.location.pathname.includes('enterprise') &&
          this.props.handleGetCalculationInfo(item.lastVersionId),
          this.setState({ isActiveFolder: i }),
          this.setState({ activeItem: item }),
          this.setState({ itemType: itemType }),
          this.setState({ openPanel: false }),
          this.setState({
            selectedCalcId:
              this.state.selectedCalcId.length > 1
                ? this.state.selectedCalcId
                : [item.id]
          }),
          this.setState({
            contextMenu:
              this.state.contextMenu === null
                ? {
                  mouseX: e.clientX - 10,
                  mouseY: e.clientY - 20
                }
                : null
          }),
          this.setState({
            contextUpdate:
              this.state.contextMenu === null
                ? {
                  mouseX: e.clientX - 10,
                  mouseY: e.clientY - 20
                }
                : {
                  mouseY: 1,
                  mouseX: 1
                }
          })
        ])
          .then(() => {
            if (itemType === 'CALCULATION') {
              window.location.pathname.includes('enterprise') &&
                this.props.handleGetCalculationInfo(item.lastVersionId);
              this.props.handleCalIdAction(
                item?.id,
                item?.title,
                0,
                0,
                item?.description,
                id
              );
            } else if (itemType === 'FILE') {
              this.setState({
                file: {
                  url: item?.url,
                  fileName: item?.fileName
                }
              });
            } else {
              this.props.handleCalIdAction(item?.id, '', 0, 0, '', id);
            }
          })
          .catch(error => console.error(error));
      }
    };

    handleCloseContextMenu = () => {
      this.setState({
        contextMenu: null
      });
    };

    onBeforeCapture = async (start: any) => {
      const draggableId = start.draggableId.split('-').reverse();
      const selected = this.state.selectedTaskIndex.find(
        (taskId: any) => taskId === Number(draggableId[0])
      );
      // if dragging an item that is not selected - unselect all items
      if (selected === undefined) {
        this.setState({ selectedTaskIndex: [Number(draggableId[0])] });
      }
    };

    handlerDragEnd = async (result: any) => {
      const { destination, draggableId } = result;

      if (destination !== null) {
        const draggbleData = draggableId.split('-');
        const droppableData = destination.droppableId.split('-');

        if (draggbleData[0] === 'CALCULATION') {
          if (droppableData[0] === 'FOLDER') {
            await this.props.handleMoveCal(draggbleData[1], droppableData[1]);
            this.props.handleEncalDataAction(this.props.workspace);
            this.props.handleToastSetupActionPopUp(
              'INFO',
              draggbleData[2] + ' has been moving to ' + droppableData[2]
            );
          } else if (droppableData[0] === 'CALCULATION') {
            const index = checkIndex(
              this.state.selectedTaskIndex?.length > 1
                ? this.state.selectedTaskIndex
                : [result.source.index],
              destination
            );
            if (index) return;

            const newDataEncal = this.props.encal.data.filter((data: any) => {
              return !this.state.selectedCalcId.includes(data.id as never);
            });
            const filteredDataEncal = this.props.encal.data.filter(
              (data: any) => {
                return this.state.selectedCalcId.includes(data.id as never);
              }
            );

            newDataEncal.splice(destination.index, 0, ...filteredDataEncal);
            this.props.handleChangeEncalDataAction(newDataEncal);

            await this.props.handleReorderCal(
              this.props.workspace,
              result.destination.index,
              this.state.selectedTaskIndex?.length > 1
                ? this.state.selectedTaskIndex
                : [result.source.index]
            );
          }
        }
      }
    };

    renderEnterPriseCalculationMenuContext = () => (
      <Menu
        open={Boolean(this.state.contextMenu)}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.contextMenu !== null
            ? {
              top: this.state.contextUpdate.mouseY,
              left: this.state.contextUpdate.mouseX
            }
            : undefined
        }
        PaperProps={{
          style: {
            width: 180
          }
        }}
        onClose={this.handleCloseContextMenu}
      >
        <MenuList sx={{ outline: 'none' }}>
          {checkProfileRole(getUserProfile()) <= 5 && (
            <MenuItem>
              <ListItemText
                onClick={this.handleCopyToMyCalcs}
                data-testid="rkCopyToMyCalc"
              >
                Copy to my calcs
              </ListItemText>
            </MenuItem>
          )}
          {checkProfileRole(getUserProfile()) <= 4 && (
            <>
              <MenuItem data-testid="rkProposeToEdit">
                <ListItemText>Propose to edits</ListItemText>
              </MenuItem>
              <MenuItem data-testid="rkProposeToDelete">
                <ListItemText>Propose to delete</ListItemText>
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
    );

    isActiveItemHandle = (row: any) => {
      this.state.isActiveItem = row;
    };

    openAdditionalFunctionsAccountManager = (
      e: MouseEvent,
      i: number,
      row: any
    ) => {
      e.preventDefault();
      this.props.handleIsActiveItem(row.isActive);
      if (checkProfileRole(getUserProfile()) >= 6) {
        return;
      } else {
        Promise.all([
          this.setState({ activeItem: row }),
          this.setState({ isActiveItem: row.isActive }),
          this.setState({
            contextMenu:
              this.state.contextMenu === null
                ? {
                  mouseX: e.clientX - 0,
                  mouseY: e.clientY - 20
                }
                : null
          }),
          this.setState({
            contextUpdate:
              this.state.contextMenu === null
                ? {
                  mouseX: e.clientX - 0,
                  mouseY: e.clientY - 20
                }
                : {
                  mouseY: 1,
                  mouseX: 1
                }
          })
        ])
          .then(() => {
            this.state.activeItem = row;
          })
          .catch(error => console.error(error));
      }
    };

    renderAdditionalFunctionsAccountManager = () => (
      <Menu
        open={Boolean(this.state.contextMenu)}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.contextMenu !== null
            ? {
              top: this.state.contextUpdate.mouseY,
              left: this.state.contextUpdate.mouseX
            }
            : undefined
        }
        PaperProps={{
          style: {
            width: 180
          }
        }}
        onClose={this.handleCloseContextMenu}
      >
        <MenuList sx={{ outline: 'none' }}>
          {checkProfileRole(getUserProfile()) == 1 && (
            <div>
              <MenuItem>
                <ListItemText onClick={this.handleEditUser}>Edit</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText
                  onClick={() => this.handleChangePlan(this.state.activeItem)}
                >
                  Change Plan
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemTextBilling activeItem={this.state.activeItem}>
                  Billing Details
                </ListItemTextBilling>
              </MenuItem>
              <MenuItem>
                <ListItemText>Detail Activity</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText onClick={this.handleResetPassword}>
                  Reset Password
                </ListItemText>
              </MenuItem>
              {this.state.isActiveItem ? (
                <ListItem>
                  <ListItemText
                    style={{
                      color: ' #DE294C'
                    }}
                    onClick={this.handleDeactivatedUser}
                  >
                    Deactivated
                  </ListItemText>
                </ListItem>
              ) : (
                <ListItemI>
                  <ListItemText onClick={this.handleActiveUser}>
                    Activated
                  </ListItemText>
                </ListItemI>
              )}
            </div>
          )}
        </MenuList>
      </Menu>
    );

    renderMyCalculationMenuContext = (
      plan: string,
      isHaveManageFolder: boolean
    ) => (
      <Menu
        open={Boolean(this.state.contextMenu)}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.contextMenu !== null
            ? {
              top: this.state.contextUpdate.mouseY,
              left: this.state.contextUpdate.mouseX
            }
            : undefined
        }
        PaperProps={{
          style: {
            width: 190
          }
        }}
        onClose={this.handleCloseContextMenu}
      >
        <MenuList sx={{ outline: 'none' }}>
          <MenuItem>
            <ListItemText
              data-testid={
                this.state.itemType === 'CALCULATION'
                  ? 'rkRenameCalculation'
                  : this.state.itemType === 'FILE'
                    ? 'rkRenameFileExcel'
                    : 'rkRenameFolder'
              }
              onClick={this.handleRenameItem}
            >
              Rename
            </ListItemText>
          </MenuItem>
          {this.state.itemType === 'CALCULATION' && (
            <MenuItem>
              <ListItemText
                data-testid="rkMakeACopyCalculation"
                onClick={this.handleCopyItem}
              >
                Duplicate
              </ListItemText>
            </MenuItem>
          )}
          {this.state.itemType === 'CALCULATION' &&
            !/FREE/.test(plan) &&
            isHaveManageFolder && (
              <MenuItem>
                <ListItemText
                  data-testid="rkMakeACopyCalculation"
                  onClick={() => {
                    this.props.handleModalPopup(
                      'COPY_CALCULATION_TO_BUSINESS_DRIVE',
                      this.state.activeItem
                    );
                    this.handleCloseContextMenu();
                  }}
                >
                  Copy to
                </ListItemText>
              </MenuItem>
            )}
          <MenuItem>
            <ListItemText
              data-testid={
                this.state.itemType === 'CALCULATION'
                  ? 'rkMoveCalculation'
                  : this.state.itemType === 'FILE'
                    ? 'rkMoveFileExcel'
                    : 'rkMoveFolder'
              }
              onClick={this.handleMoveItem}
            >
              Move
            </ListItemText>
          </MenuItem>
          {this.state.itemType === 'CALCULATION' && !this.isComposer && (
            <MenuItem>
              <ListItemText data-testid="rkShareCalculation">
                Share
              </ListItemText>
            </MenuItem>
          )}
          {this.state.itemType === 'CALCULATION' && !this.isComposer && (
            <MenuItem
              onClick={() => {
                this.handleCopyItems('Community');
              }}
              data-testid="rkPublishToCommunity"
            >
              <ListItemText>Publish to Community</ListItemText>
            </MenuItem>
          )}
          {this.state.itemType === 'FILE' && (
            <MenuItem>
              <ListItemText
                data-testid="rkDownloadFileExcel"
                onClick={this.handleDownloadItem}
              >
                Download
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem>
            <ListItemText
              style={{ color: 'red' }}
              onClick={this.handleDeleteItem}
              data-testid={
                this.state.itemType === 'CALCULATION'
                  ? 'rkDeleteCalculation'
                  : this.state.itemType === 'FILE'
                    ? 'rkDeleteFileExcel'
                    : 'rkDeleteFolder'
              }
            >
              Delete
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    );

    renderCommunityMenuContext = () => {
      if (this.isRekava) {
        return (
          <>
            <Menu
              open={Boolean(this.state.contextMenu)}
              anchorReference="anchorPosition"
              anchorPosition={
                this.state.contextMenu !== null
                  ? {
                    top: this.state.contextUpdate.mouseY,
                    left: this.state.contextUpdate.mouseX
                  }
                  : undefined
              }
              PaperProps={{
                style: {
                  width: 210
                }
              }}
              onClose={this.handleCloseContextMenu}
            >
              <MenuList sx={{ outline: 'none' }}>
                {this.state.itemType === 'FOLDER' && this.isRekava && (
                  <MenuItem>
                    <ListItemText
                      data-testid={'rkRenameFolder'}
                      onClick={this.handleRenameItem}
                    >
                      Rename
                    </ListItemText>
                  </MenuItem>
                )}
                {this.state.itemType !== 'FOLDER' && this.isRekava && (
                  <MenuItem
                    onClick={() => {
                      this.handleCopyItems('myCalculations');
                    }}
                  >
                    <ListItemText data-testid={'rCopyCalc'}>
                      Copy to My Calculation
                    </ListItemText>
                  </MenuItem>
                )}
                {this.isRekava && (
                  <MenuItem>
                    <ListItemText
                      data-testid={'rkMoveFolder'}
                      onClick={this.handleMoveItemCommunity}
                    >
                      Move
                    </ListItemText>
                  </MenuItem>
                )}
                {this.isRekava && (
                  <MenuItem>
                    <ListItemText
                      primary={
                        <Typography
                          type="TITLE"
                          style={{ color: 'red', fontSize: 16 }}
                        >
                          Delete
                        </Typography>
                      }
                      onClick={() => {
                        this.handleDeleteItem('community');
                      }}
                      data-testid={'rkDeleteFolder'}
                    ></ListItemText>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </>
        );
      }
    };

    // TO DO HANDLE ACTION BUTTON FUNCTION
    handleRenameItem = (type?: any) => {
      this.handleCloseContextMenu();
      if (this.state.itemType === 'FOLDER') {
        this.props.handleModalPopup(
          'RENAME_FOLDER',
          (this.state.activeItem = {
            ...this.state.activeItem,
            type
          })
        );
      }
      if (this.state.itemType === 'CALCULATION') {
        this.props.handleModalPopup('RENAME_CALCULATION_FROM_MYCALCULATION');
      }
      if (this.state.itemType === 'FILE') {
        this.props.handleModalPopup('RENAME_FILE', this.state.activeItem);
      }
    };

    handleCopyItems = (location?: string) => {
      this.handleCloseContextMenu();
      if (
        /REKAVA_ADMIN/.test(getUserProfile() ?? '') &&
        location === 'Community'
      ) {
        {
          this.props.handleModalPopup('PUBLISH_MULTIPLE_CALCULATIONS', {
            personalWorkspaceId: this.props.login,
            selectedCommId: this.state.selectedCalcId,
            text: location
          });
        }
      } else {
        if (
          checkSubscriptionAndDate(
            this.props.login['subscription'],
            this.props.users['userLimit'],
            this.props.handleDispatch
          )
        ) {
          this.props.handleModalPopup('PUBLISH_MULTIPLE_CALCULATIONS', {
            personalWorkspaceId: this.props.login,
            selectedCommId: this.state.selectedCalcId,
            text: location === 'Community' ? 'Community' : 'my calculations'
          });
        }
      }
    };

    handleCopyItem = () => {
      this.handleCloseContextMenu();
      if (
        checkSubscriptionAndDate(
          this.props.login['subscription'],
          this.props.users['userLimit'],
          this.props.handleDispatch
        )
      ) {
        this.props.handleModalPopup('MAKE_COPY_CALCULATION_FROM_MYCALCULATION');
      }
    };

    handleMoveItem = () => {
      this.handleCloseContextMenu();
      if (this.state.itemType === 'FOLDER') {
        this.props.handleModalPopup('MOVE_FOLDER', this.state.activeItem);
      }
      if (this.state.itemType === 'CALCULATION') {
        this.props.handleModalPopup('MOVE_MULTIPLE_CALCULATION', {
          calInfo: this.props.calInfo,
          activeIds: this.state.selectedCalcId
        });
      }
      if (this.state.itemType === 'FILE') {
        this.props.handleModalPopup('MOVE_FILE', this.state.activeItem);
      }
    };

    handleMoveItemCommunity = () => {
      this.handleCloseContextMenu();
      if (this.state.itemType === 'FOLDER') {
        this.props.handleModalPopup('MOVE_FOLDER', this.state.activeItem);
      }
      if (this.state.itemType === 'CALCULATION') {
        this.props.handleModalPopup('MOVE_MULTIPLE_CALCULATION', {
          calInfo: this.props.calInfo,
          activeIds: this.state.selectedCalcId
        });
      }
      if (this.state.itemType === 'FILE') {
        this.props.handleModalPopup('MOVE_FILE', this.state.activeItem);
      }
    };

    handleDownloadItem = () => {
      downloadFile(this.state.file.url, this.state.file.fileName);
      this.handleCloseContextMenu();
    };

    handleDeleteItem = (type?: any) => {
      this.handleCloseContextMenu();
      if (this.state.itemType === 'FOLDER') {
        this.props.handleModalPopup(
          'CONFIRMATION_DELETE_FOLDER',
          (this.state.activeItem = { ...this.state.activeItem, type })
        );
      }
      if (this.state.itemType === 'CALCULATION') {
        this.props.handleModalPopup(
          'CONFIRMATION_DELETE_MULTIPLE_CALCULATION',
          this.state.selectedCalcId
        );
      }
      if (this.state.itemType === 'FILE') {
        this.props.handleModalPopup(
          'CONFIRMATION_DELETE_MULTIPLE_CALCULATION',
          this.state.selectedCommId
        );
      }
      if (this.state.itemType === 'FILE') {
        this.props.handleModalPopup(
          'CONFIRMATION_DELETE_FILE',
          this.state.activeItem
        );
      }

      this.handleInActiveFocus();
    };

    handlePublish = (label: string) => {
      this.handleCloseContextMenu();
      if (label === 'Enterprise') {
        this.props.handleModalPopup(
          'PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_MYCALCULATION',
          {
            location: getEnterpriseWorkspaceId()
          }
        );
      } else {
        this.props.handleModalPopup(
          'PUBLISH_TO_COMMUNITY_CALCULATION_FROM_MYCALCULATION',
          {
            location: getCommunityWorkspaceId()
          }
        );
      }
    };

    handleCopyToMyCalcs = () => {
      this.handleCloseContextMenu();
      this.props.handleModalPopup('COPY_TO_MYCALC_FROM_ENTERPRISE', {
        ...this.props.calInfo,
        data: this.props.calculations,
        personalWorkspaceId: getPersonalWorkspaceId()
      });
    };

    handleFetchResources = async (id: string, location: any) => {
      try {
        await Promise.all([
          this.props.handleEncalDataAction(id),
          this.props.handleGetBreadcumbs(id),
          this.props.handleSetWorkspace(id)
        ]).then(response => {
          location?.state &&
            this.checkRecentCalculation(location, response[0]).catch(error =>
              console.error(error)
            );
        });
      } catch (error: any) {
        console.error(error);
      }
    };

    // ADDITIONAL FUNCTION ACCOUT MANAGER FUNCTION

    handleResetPassword = () => {
      this.handleCloseContextMenu();
      this.props.handleModalPopup(
        'RESET_PASSWORD_ACCOUNT_MANAGER',
        this.state.activeItem
      );
    };

    handleDeactivatedUser = async () => {
      this.handleCloseContextMenu();
      this.props.handleModalPopup(
        'DEACTIVATE_ACCOUNT_MANAGER',
        this.state.activeItem
      );
    };

    handleActiveUser = async () => {
      this.props.handleModalPopup(
        'ACTIVATE_USER_ACCOUNT_MANAGER',
        this.state.activeItem
      );

      this.handleCloseContextMenu();
    };

    handleEditUser = async () => {
      this.props.handleIsActiveUser(this.state.activeItem);
      Promise.all([this.props.handleFetchAllCompanies()]).then(response => {
        this.props.handleModalPopup('EDIT_ACCOUNT_MANAGER', {
          res: response[0],
          activeItem: this.state.activeItem,
          data: this.props.users.dataUsers,
        });
      });
      this.handleCloseContextMenu();
    };

    handleChangePlan = async (subscriptionUser: any) => {
      this.props.handleModalPopup('HANDLE_CHANGE_PLANS', {
        subscriptionUser,
        subscriptionList: this.state.subscriptionList
      });
      this.handleCloseContextMenu();
    };

    handleOpenTutorial = async (payload: any) => {
      try {
        const result: any = await this.props.handleFetchTutorial(payload.id);
        this.props.handleFormHelperPanel(true, 'TUTORIAL', result.data);
      } catch (error: any) {
        console.error(error);
      }
    };

    handleStartCalculation = async (calculationId: string, history: any) => {
      try {
        await Promise.all([
          this.props.handleFetchInputFileList(calculationId)
        ]).then(response => {
          this.props.handleModalPopup('START_CALCULATION', {
            calculationId: calculationId,
            inputFileList: response[0],
            history
          });
        });
      } catch (error: any) {
        console.error(error);
      }
    };

    checkRecentCalculation = async (payload: any, data: any) => {
      try {
        const calculation: any = await findRecentCalculation(
          data,
          payload.state.calculationId
        );
        this.handleCurrentCalculation(calculation);
      } catch (error: any) {
        console.error(error);
      }
    };

    handleCurrentCalculation = async (calculation: any) => {
      try {
        Promise.all([
          this.setState({ isActiveFolder: calculation.index }),
          this.setState({ activeItem: calculation }),
          this.setState({ openPanel: true }),
          this.setState({ selectedCalcId: [calculation.id] })
        ])
          .then(() => {
            this.props.handleCalIdAction(
              calculation.id,
              calculation.title,
              calculation.numberOfInputFiles,
              calculation.numberOfApproval,
              calculation.description,
              calculation.folderId
            );
          })
          .catch(error => console.error(error));
      } catch (error: any) {
        console.error(error);
      }
    };

    handlePreviewCalculation = (calculation: any) => {
      this.props.handleModalPopup('PREVIEW_CALCULATION', {
        calculation,
        userLimit: this.props.users['userLimit']
      });
    };

    //BUSINESS DRIVE SECTION

    handleGetResourceTeamDrives = (id?: any, location?: locationI) => {
      Promise.all([
        this.props.handleFetchTeamDrives(id),
        id && this.props.handleGetBreadcumbs(id, 'TEAM')
      ])
        .then((response: Array<FolderDriveI | undefined>) => {
          location?.state &&
            response[0]?.items.length &&
            this.checkRecentCalculation(location, response[0]?.items).catch(
              error => console.error(error)
            );
        })
        .catch((error: any) => console.log(error));
    };

    handleActiveDrive = (
      e: MouseEvent,
      i: number,
      row: any,
      parentId: string
    ) => {
      e.stopPropagation();
      Promise.all([
        this.setState({ isActiveFolder: i }),
        this.setState({ activeItem: row }),
        this.setState({ itemType: row?.itemType ?? 'TEAM' })
      ])
        .then(() => {
          if (row?.itemType === 'CALCULATION') {
            this.setState({ openPanel: true });
            this.props.handleCalIdAction(
              row.id,
              row.title,
              row.numberOfInputFiles,
              row.numberOfApproval,
              row.description,
              parentId
            );
            this.setState({
              itemType: row?.itemType
            });
          } else {
            this.props.handleCalIdAction(row.id, '', 0, 0, '', parentId);
            this.setState({
              itemType: ''
            });
          }
        })
        .catch(error => console.error(error));
    };

    handleRenameDrive = () => {
      const data: any = this.state.activeItem;
      this.handleCloseContextMenu();
      this.props.handleModalPopup('RENAME_TEAM_DRIVES', {
        ...data,
        isDrive: !Boolean(data?.type === 'TEAM')
      });
    };

    handleRenameCalculationDrive = () => {
      const data: any = this.state.activeItem;
      this.handleCloseContextMenu();
      this.props.handleModalPopup('RENAME_CALCULATION_FROM_DRIVE', {
        data
      });
    };

    handleDuplicateCalculationDrive = () => {
      this.handleCloseContextMenu();
      this.props.handleModalPopup('MAKE_COPY_CALCULATION_FROM_DRIVE', {
        item: this.state.activeItem
      });
    };

    renderTeamDrivesMenuContext = () => (
      <Menu
        open={Boolean(this.state.contextMenu)}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.contextMenu !== null
            ? {
              top: this.state.contextUpdate.mouseY,
              left: this.state.contextUpdate.mouseX
            }
            : undefined
        }
        PaperProps={{
          style: {
            width: 210
          }
        }}
        onClose={this.handleCloseContextMenu}
      >
        <MenuList sx={{ outline: 'none' }}>
          {this.state.itemType === 'TEAM' && (
            <MenuItem>
              <ListItemText
                data-testid={'rRenameDrive'}
                onClick={this.handleRenameDrive}
              >
                Rename
              </ListItemText>
            </MenuItem>
          )}
          {this.state.itemType === 'CALCULATION' && (
            <>
              <MenuItem>
                <ListItemText
                  data-testid={'rRenameCalc'}
                  onClick={this.handleRenameCalculationDrive}
                >
                  Rename
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText
                  data-testid={'rDuplicateCalc'}
                  onClick={this.handleDuplicateCalculationDrive}
                >
                  Duplicate
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText data-testid={'rMoveeCalc'}>Move</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText
                  data-testid={'rDeleteCalc'}
                  style={{ color: 'red' }}
                >
                  <Typography style={{ color: 'red', fontSize: 16 }}>
                    Delete
                  </Typography>
                </ListItemText>
              </MenuItem>
            </>
          )}
          {this.state.itemType === 'FOLDER' && (
            <>
              <MenuItem>
                <ListItemText
                  onClick={() => {
                    this.handleRenameItem('TEAM');
                  }}
                >
                  Rename
                </ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText
                  primary={
                    <Typography
                      type="TITLE"
                      style={{ color: 'red', fontSize: 16 }}
                    >
                      Delete
                    </Typography>
                  }
                  onClick={() => {
                    this.handleDeleteItem('TEAM');
                  }}
                />
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
    );

    handleCalculationMenuTeamDrives = (e: any, item: any, i: number) => {
      e.preventDefault();
      Promise.all([
        this.setState({ isActiveFolder: i }),
        this.setState({ activeItem: item }),
        this.setState({ openPanel: false }),
        this.setState({ itemType: item?.itemType ?? 'CALCULATION' }),
        this.setState({
          contextMenu:
            this.state.contextMenu === null
              ? {
                mouseX: e.clientX - 10,
                mouseY: e.clientY - 20
              }
              : null
        }),
        this.setState({
          contextUpdate:
            this.state.contextMenu === null
              ? {
                mouseX: e.clientX - 10,
                mouseY: e.clientY - 20
              }
              : {
                mouseY: 1,
                mouseX: 1
              }
        })
      ])
        .then(() => {
          return;
        })
        .catch(error => console.error(error));
    };

    handleContextMenuTeamDrives = (e: any, item: any, i: number) => {
      e.preventDefault();
      Promise.all([
        this.setState({ isActiveFolder: i }),
        this.setState({ activeItem: item }),
        this.setState({ itemType: item?.itemType ?? 'TEAM' }),
        this.setState({
          contextMenu:
            this.state.contextMenu === null
              ? {
                mouseX: e.clientX - 10,
                mouseY: e.clientY - 20
              }
              : null
        }),
        this.setState({
          contextUpdate:
            this.state.contextMenu === null
              ? {
                mouseX: e.clientX - 10,
                mouseY: e.clientY - 20
              }
              : {
                mouseY: 1,
                mouseX: 1
              }
        })
      ])
        .then(() => {
          return;
        })
        .catch(error => console.error(error));
    };

    fetchSubscriptionList = () => {
      UserService.getListSubscription().then((response: any) => {
        const convertedData: string[] = response?.data?.map(
          (item: Record<string, unknown>) => item['pretty']
        );
        this.setState({
          subscriptionList: convertedData
        });
      });
    };

    handleShowMobileSidebar = () => {
      this.setState({
        isShowMobileSidebar: !this.state.isShowMobileSidebar
      });
    };

    render() {
      const {
        logger,
        calInfo,
        itemTitle,
        encal,
        global,
        login,
        calculations,
        toastPopUp,
        users,
        navigation,
        handleEncalDataAction,
        handleModalPopup,
        handleSetWorkspace,
        workspace,
        sidebar,
        ...restProps
      } = this.props;
      const { typeView } = global;
      const {
        isActiveFolder,
        styleView,
        activeItem,
        isMinimize,
        openPanel,
        isActiveItem,
        selectedTaskIndex,
        selectedCalcId,
        isShowMobileSidebar
      } = this.state;

      const breadCrumbsData = navigation.breadcrumb;
      const breadcrumb =
        breadCrumbsData.length <= 3
          ? breadCrumbsData
          : [breadCrumbsData[0]].concat(
            [{ name: '...', id: '' }],
            breadCrumbsData.slice(-2)
          );

      return (
        <Fragment>
          <CalculationsMenu
            styleViewEnabled={true}
            handleSelectStyle={this.handleSelectStyleView}
            styleView={this.state.styleView}
            handleMinimizeSidebar={this.handleMinimizeSidebar}
            handleModal={handleModalPopup}
            handleShowMobileSidebar={this.handleShowMobileSidebar}
            {...restProps}
          />
          <BaseComponent
            encal={encal}
            calInfo={calInfo}
            global={global}
            login={login}
            users={users}
            selectedCommIds={selectedCalcId}
            selectedTask={selectedTaskIndex}
            handleActiveFocus={this.handleActiveFocus}
            handleActiveFocusFolder={this.handleActiveFocusFolder}
            handleContextMenu={this.handleContextMenu}
            renderMyCalculationMenuContext={this.renderMyCalculationMenuContext}
            renderCommunityMenuContext={this.renderCommunityMenuContext}
            renderEnterPriseCalculationMenuContext={
              this.renderEnterPriseCalculationMenuContext
            }
            renderAdditionalFunctionsAccountManager={
              this.renderAdditionalFunctionsAccountManager
            }
            breadCrumbsData={breadcrumb}
            isActiveFolder={isActiveFolder}
            isActiveItem={isActiveItem}
            styleView={styleView}
            activeItem={activeItem}
            isMinimize={isMinimize}
            handleModal={handleModalPopup}
            handleInActiveFocus={this.handleInActiveFocus}
            fetchResources={this.handleFetchResources}
            calculationDetail={calculations}
            openAdditionalFunctionsAccountManager={
              this.openAdditionalFunctionsAccountManager
            }
            isActiveItemHandle={this.isActiveItemHandle}
            openPanel={openPanel}
            openTutorial={this.handleOpenTutorial}
            startCalculation={this.handleStartCalculation}
            clearRecentInputFile={this.props.handleClearRecentInputFile}
            handlerDragEnd={this.handlerDragEnd}
            onBeforeCapture={this.onBeforeCapture}
            handlePreviewCalculation={this.handlePreviewCalculation}
            fetchTeamDrives={this.handleGetResourceTeamDrives}
            handleSetActiveDrive={this.handleActiveDrive}
            renderTeamDrivesMenuContext={this.renderTeamDrivesMenuContext}
            handleCalculationMenuTeamDrives={
              this.handleCalculationMenuTeamDrives
            }
            handleContextMenuTeamDrives={this.handleContextMenuTeamDrives}
            fetchSubscriptionList={this.fetchSubscriptionList}
            isShowMobileSidebar={isShowMobileSidebar}
            handleShowMobileSidebar={this.handleShowMobileSidebar}
            sidebar={sidebar}
            {...(restProps as BaseProps)}
          />
        </Fragment>
      );
    }
  }

  const ConnectedHoc = connect(mapStateToProps, mapDispatchToProps)(Hoc);

  return ConnectedHoc;
};
