import { styled } from "@mui/material";

interface PropsI {
  isError: boolean;
  isDisabled: boolean;
  isMobile?: boolean
  isFocused?: boolean;
}

interface AcePropsI {
  isAce: boolean;
}

interface SourcePropsI {
  lineHeight?: number;
  isSplitExcerpts?: boolean;
  isExpandExcerpts?: boolean;
  isMobile?: boolean;
}

interface DisclaimerPropsI {
  isMobile?: boolean;
}

interface ConversationListItemPropsI {
  isActive: boolean;
}

interface LoadMorePropsI {
  top: number;
  isLoading: boolean;
}


interface RightPanelPropsI {
  isMobile?: boolean;
  height?: number;
}

interface MainContentPropsI {
  isCenter?: boolean;
}

interface AceBottomSideBarPropsI {
  selected: boolean;
}

interface RightSectionPropsI {
  isMobile?: boolean;
}

interface SourceListPropsI {
  selected?: boolean;
}

export const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const MainContent = styled('div')<MainContentPropsI>(({ isCenter }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  ...(isCenter && { justifyContent: 'center' })
}));

export const RightSection = styled('section')<RightSectionPropsI>(({ isMobile }) => ({
  width: isMobile ? 0 : 240,
  position: 'relative',
  display: 'flex',
}));

export const BottomSidebarContainer = styled('div')({
  bottom: 12,
  position: 'absolute',
  left: 12,
  right: 12,
  width: 240,
});

export const BottomSidebarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: "12px",
  cursor: 'pointer',
});

export const SidebarItem = styled('div')<AceBottomSideBarPropsI>(({ selected, theme }) => ({
  display: 'flex',
  flexDirection: "row",
  columnGap: '8px',
  padding: "12px 4px",
  ...(selected && { backgroundColor: theme.palette.info.light }),
}));

export const DisclaimerContent = styled('div')<DisclaimerPropsI>(({ isMobile, theme }) => ({
  width: isMobile ? '78%' : 200,
  padding: 20,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 8,
  ...(isMobile && {
    right: 0,
    left: 0,
    margin: 'auto',
  })
}));

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'space-between',
  overflowY: 'auto',
  overflowX: 'hidden'
});

export const InputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 30,
  padding: '1rem 0 2rem',
  width: '100%',
});


export const InputWrapper = styled('div')<PropsI>(({ isError, isDisabled, theme, isMobile, isFocused }) => ({
  display: "flex",
  flexDirection: 'row',
  border: isError ? "#DE294C 1px solid" : isFocused ? "#118CFF 1px solid" : "#BFC9D9 1px solid",
  borderRadius: "8px",
  padding: "12px",
  margin: '0px 12px',
  ...(isDisabled && { backgroundColor: theme.palette.action.disabledBackground }),
  width: isMobile ? '85%' : 672,
  "& > #input": {
    flex: 1,
    borderRadius: "8px",
    padding: "8px 12px",
    border: "none",
    color: isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
    ...(isDisabled && { backgroundColor: theme.palette.action.disabledBackground }),
    "&::placeholder": {
      color: "#A3B3C2",
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
}));

export const ChatWrapper = styled('div')<AcePropsI>(({ isAce, theme }) => ({
  padding: isAce ? '16px' : "0px 16px",
  borderRadius: '16px',
  ...(isAce && { boxShadow: '0px 0px 12px rgba(104, 109, 142, 0.24)' }),
  margin: "22px 16px",
  "& > #chatbox": {
    flexDirection: 'row',
    display: 'flex',
    // width: '640px',
    alignItems: 'flex-start',
    padding: '8px 0',
    "& > #avatar": {
      display: 'flex',
      marginRight: 16,
      width: 32,
      height: 32,
      borderRadius: 50,
      objectFit: 'cover',
    },
    "& > #taylor": {
      marginRight: 16,
      padding: 4,
      backgroundColor: '#E3F3FF',
      borderRadius: 50,
      display: "flex",
      justifyContent: "center"
    },
    "& > #message": {
      maxWidth: 610,
      color: isAce ? "#1676D5" : theme.palette.text.primary,
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",

      "& table": {
        marginTop: '1rem',
        borderCollapse: 'collapse',

        "& td": {
          verticalAlign: 'initial',
        },

        "& th, td": {
          border: '1px solid',
          padding: '0.5rem',

        }
      },

      "& > p": {
        marginBottom: 20
      },

      // SQL Agent Table Answer
      "& > pre": {
        background: theme.palette.action.disabledBackground,
        padding: 12,
        borderRadius: 4,
        height: 'auto !important',
        width: 'auto !important',
        overflow: 'auto !important'
      }

    },
  },
  "& > #source": {
    marginTop: '36px',
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export const SourceDetail = styled('div')<SourcePropsI>(({ lineHeight, isSplitExcerpts, isExpandExcerpts, isMobile }) => ({
  marginTop: '12px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'row',
  "& > #leftSection": {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    "& > #line": {
      display: 'flex',
      height: lineHeight,
      width: '2px',
      backgroundColor: '#BAE2FF',
      marginLeft: '9px',
      position: 'absolute',
      top: '36px'
    },
    "& > #dotContainer": {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '14px',
      marginTop: '16px',
      "& > #dotLarge": {
        width: '20px',
        display: 'flex',
        height: '20px',
        borderRadius: '50px',
        backgroundColor: '#E3F3FF',
        justifyContent: 'center',
        alignItems: 'center',
        "& > #dotSmall": {
          width: '14px',
          display: 'flex',
          height: '14px',
          borderRadius: '50px',
          backgroundColor: '#118CFF',
        },
      },
    },
  },
  "& > #sourceWrapper": {
    width: isMobile ? '80%' : '600px',
    display: 'flex',
    borderRadius: '8px',
    backgroundColor: '#F0F2F5',
    padding: 16,
    flexDirection: 'column',
    "& > #sourceTitle": {
      marginBottom: 8
    },
    "& > #excerptsContainer": {
      display: 'flex',
      flexDirection: 'column',
      ...(isSplitExcerpts && !isExpandExcerpts && {
        height: 80,
        overflow: 'hidden',
        marginBottom: 8
      }),
      "& > #line": {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 8,
      },
    },

  },
}));

export const SourceContentWrapper = styled('div')<RightPanelPropsI>(({ isMobile, height }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 12,
  width: '100%',
  overflow: 'hidden',

  '& > p': {
    padding: '16px 0 0 16px',
    marginBottom: '8px !important'
  },

  "& > #content": {
    ...(isMobile ? { height } : { flex: '0 0 50%' }),
    overflow: 'auto',
    '& > li > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}));


export const SourceListItem = styled('li')<SourceListPropsI>(({ selected, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  ...(selected && { backgroundColor: theme.palette.info.light }),
  gap: 6,
  "& > #title": {
    display: 'flex',
    columnGap: 8,
    flexDirection: 'row',
    alignItems: 'center',
  }
}));

export const MobileInfoPanelWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '15px 0 12px 0',
  "& > #headerWrapper": {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& > #logo": {
      display: 'flex',
      paddingLeft: '16px',
      flexDirection: 'row',
      marginBottom: '12px',
      alignItems: 'center',
      columnGap: '16px'
    },
  },
  "& > #header": {
    display: 'flex',
    backgroundColor: '#F0F2F5',
    borderTop: '1px solid #D6DFEB',
    borderBottom: '1px solid #D6DFEB',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 16px',
    columnGap: '16px'
  },
  "& > #content": {
    display: 'flex',
  },
}));

export const ConversationListItem = styled('li')<ConversationListItemPropsI>(({ isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: "12px 16px",
  ...(isActive && { backgroundColor: "#E3F3FF" }),
  "&:hover": {
    backgroundColor: "#E3F3FF",
  },
}));

export const CitationWrapper = styled('section')({ display: 'flex', flexDirection: 'column', gap: 12, marginTop: 32 })

export const CitationList = styled('section')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 12,
  flexWrap: 'wrap',
  minWidth: 0, width: 668,
})

interface CitationItemPropsI {
  active?: boolean
  isHcss?: boolean;
}

interface CitationDetailPropsI {
  isDrawing?: boolean
}

export const CitationItem = styled('span')({
  display: 'inline-flex',
  padding: '8px 8px 8px 12px',
  alignItems: 'center',
  gap: 6,

  borderRadius: 6,
  minWidth: 0,

  '& > span': {
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    overflow: "hidden",
    cursor: 'pointer'
  }
});

export const SourceItem = styled(CitationItem)<CitationItemPropsI>(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.info.light : '#fff',
  borderRadius: 6,
  border: "1px solid #D6DFEB",
  padding: "8px 8px",
  '&:hover': {
    backgroundColor: active ? theme.palette.info.light : theme.palette.secondary.main,
  }
}))

export const CitationMoreButton = styled(CitationItem)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main
}))

export const DrawingItem = styled(CitationItem)<CitationItemPropsI>(({ active, isHcss }) => ({
  backgroundColor: isHcss ? '#D1D9E0' : active ? '#F5BFC9' : '#FBE5EA',
}))

export const CitationDetail = styled('section')<CitationDetailPropsI>(({ isDrawing = false }) => ({
  display: 'flex',
  width: 624,
  padding: 16,
  flexDirection: 'column',
  gap: 8,
  position: 'relative',
  borderRadius: 12,
  background: isDrawing ? '#FBE5EA' : '#E3F3FF',

  '& > h5, & > span': {
    maxWidth: '95%',
    lineHeight: '12px',
  }
}))

export const CitationDetailButton = styled('span')({ position: 'absolute', top: 16, right: 16 })

export const LoadMoreContainer = styled('div')<LoadMorePropsI>(({ top, isLoading }) => ({
  position: 'fixed',
  zIndex: 10,
  backgroundColor: '#F0F2F5',
  padding: isLoading ? "0px 16px 16px 16px" : "8px 16px",
  borderRadius: 20,
  top: top,
  ...(isLoading && { height: 30 }),
  left: '50%',
  transform: 'translate(-50%, -50%)',
  pointer: 'cursor',
}));

export const CitationFigureDetail = styled('span')({
  fontSize: 12,
  lineHeight: '18px',
  "& > #message": {
    color: '29333D',
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "18px",
  },
  "& > #seeMore": {
    marginTop: 16,
    color: '#118CFF',
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "18px",
    cursor: 'pointer',
  },
  '& > p': {
    lineHeight: '18px',
    marginBottom: 20
  }
})

export const CitationFigureInfo = styled('p')({
  marginTop: '1rem'
})

export const tableRowStyle: any = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  letterSpacing: "1.5px",
  color: "#29333D",
  backgroundColor: '#E0E6EB',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
};