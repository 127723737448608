import React, { useCallback, useEffect, useState } from 'react';
import {
  checkIsHaveDrive,
  getUserCalculationLimit
} from '../../store/actions/userAction';
import {
  clearStorage,
  getCommunityWorkspaceId,
  getIsConsents,
  getIsMobile,
  getPersonalWorkspaceId,
  getTutorialsList,
  getUserProfile,
  getUserTutorials,
  getUserType,
  setBusinessDriveStatus,
  setIsMobile,
  setPreventChangesCalculation,
  setTutorialsList
} from '../../utils/storage';
import {
  fetchPanelInfoList,
  fetchTutorial,
  formHelperPanel,
  showHideModalAction
} from '../../store/appState/global/globalActions';
import {
  getFolderTree,
  initTreeState,
  updateTreeState
} from '../../store/appState/sidebar/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { DashboardContainer } from '../../pageElements/dashboard';
import HelperButton from '../../components/molecules/HelperButton';
import Icon from '../../components/atoms/Icon';
import ModalAction from '../../components/molecules/modal/modalAction';
import { ReduxState } from '../../store';
import StyledButton from '../../components/atoms/Button';
import StyledCard from '../../components/molecules/Card';
import TagManagerService from '../../service/tagManagerService';
import Text from '../../components/atoms/Text';
import { checkSubscriptionAndDate } from '../../utils/helpersFunction';
import { createNewCalc } from '../../store/actions/calculationsActions';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { getDriveStatus } from '../../store/types/userTypes';
import images from '../../assets';
import { showSoftwareLicenseAgreement } from '../../store/actions/loginActions';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { ANNOUNCEMENT_BUTTON_TEXT_CONVERT, ANNOUNCEMENT_DUE_DATE, SubscriptionType } from '../../utils/constant';
import { HelperPanelType } from '../../store/appState/global/globalReducer';
import { AnnouncementBar } from './components/AnnouncementBar';

const d1 = new Date();
const d2 = new Date(ANNOUNCEMENT_DUE_DATE);
const isConvertTriggered = d1 > d2;
const buttonText = ANNOUNCEMENT_BUTTON_TEXT_CONVERT;

export default function Dashboard() {
  const theme = useTheme();
  const history = useHistory();
  const userRole = getUserProfile();
  const dispatch = useDispatch();
  const [isMobile, setMobile] = useState<boolean>(getIsMobile());
  const tagManagerService = TagManagerService();
  const myCalcWorkspaceId = getPersonalWorkspaceId() || '';
  const communityWorkspaceId = getCommunityWorkspaceId() || '';

  const { personalWorkspaceId } = useSelector(
    (state: ReduxState) => state.login,
    shallowEqual
  );
  const { openModalAction } = useSelector(
    (state: ReduxState) => state.global,
    shallowEqual
  );

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { subscription } = useSelector((state: ReduxState) => state.login);
  const [hoverEl, setHoverEl] = useState<string>('');


  const handelOnclickCreateCalc = () => {
    if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
      tagManagerService.createCalcTiles();
      dispatch(
        formModalSetupActionPopUp('NEW_CALCULATION_FROM_COMPOSER', {
          folderIdCalculation: getPersonalWorkspaceId()
        })
      );
    }
  };

  const handleCLickAccountManager = () => {
    localStorage.removeItem('sidebar');
    history.push(`/calculations/accountManager`);
  };

  const submitModal = async (values: Record<string, unknown>) => {
    try {
      setPreventChangesCalculation({});
      await dispatch(createNewCalc(personalWorkspaceId, values, history));
      dispatch(showHideModalAction(false, ''));
    } catch (error: any) {
      return error.response.data?.message;
    }
  };
  const handleMouseLeave = () => setHoverEl('');
  const goToEnterprise = () => {
    localStorage.removeItem('sidebar');
    dispatch(getFolderTree(personalWorkspaceId));
    history.push('/calculations/my-calculation');
  };

  const checkHaveDrive = async () => {
    const response: any = await checkIsHaveDrive();
    dispatch(getDriveStatus(response));
    setBusinessDriveStatus(response);
  };

  useEffect(() => {
    const UserTutorials: any = getUserTutorials();
    const isConsentsStatus: any = getIsConsents();
    const checkUserTutorial = async (UserTutorials: any) => {
      try {
        const tutorialPage: any = await getTutorialsList().find(
          (item: any) => item.title === 'Welcome to Rekava'
        );
        const tutorialDone =
          (await UserTutorials.hasOwnProperty(tutorialPage.id)) === true;
        !tutorialDone ? openTutorial(tutorialPage) : dispatch(formHelperPanel(true, isConvertTriggered ? HelperPanelType.CONVERT_ACE : HelperPanelType.ANNOUNCEMENT));
      } catch (error: any) {
        console.error(error);
      }
    };

    if (!isMobile) {
      if (!isConsentsStatus.isConsent) {
        showSoftwareLicenseAgreement(dispatch, isConsentsStatus.policyId);
      } else {
        dispatch(initTreeState(getUserType() ?? 'REGULAR'));
        dispatch(getUserCalculationLimit());
        Promise.all([dispatch(fetchPanelInfoList()), checkHaveDrive()])
          .then(response => {
            setTutorialsList(response[0]);
            checkUserTutorial(UserTutorials).catch(error =>
              console.error(error)
            );
          })
          .catch(error => console.error(error));
      }
    }
  }, [isMobile]);

  useEffect(() => {
    dispatch(updateTreeState({ isExpanded: false }, myCalcWorkspaceId));
    dispatch(updateTreeState({ isExpanded: false }, communityWorkspaceId));
  }, []);

  const handleLogout = () => {
    tagManagerService.logout();
    clearStorage();
    window.location.reload();
  };

  const handleCont = () => {
    setMobile(false);
    setIsMobile(false);
  };

  const openTutorial = async (payload: any) => {
    try {
      const result: any = await dispatch(fetchTutorial(payload.id));
      dispatch(formHelperPanel(true, HelperPanelType.TUTORIAL, result.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  const renderDashboardPage = () => (
    <>
      {/REKAVA_ADMIN|COMPOSER_ADMIN/.test(String(userRole)) && (
        <StyledCard
          height={264}
          onClick={handleCLickAccountManager}
          content={
            hoverEl === 'user' ? (
              <img src={images.userHover} alt="icon" />
            ) : (
              <img src={images.landingUser} alt="icon" />
            )
          }
          action={
            <StyledButton
              variant="contained"
              fullWidth
              color={hoverEl === 'user' ? 'primary' : 'secondary'}
              data-testid="btnManageAccounts"
              style={{
                flexDirection: 'column',
                textTransform: 'none',
                padding: 5
              }}
            >
              <Text style={{ padding: 0, color: 'inherit' }} variant="h5">
                Manage Accounts
              </Text>
              <Text
                style={{ padding: 0, fontWeight: 400, color: 'inherit' }}
                variant="smallRegular"
              >
                Account Directory
              </Text>
            </StyledButton>
          }
          selected={hoverEl === 'user'}
          onMouseEnter={() => setHoverEl('user')}
          onMouseLeave={handleMouseLeave}
        />
      )}
      {userLimit.chatBot && (
        <StyledCard
          height={264}
          onClick={() => history.push(`/ace/public`)}
          content={<Icon icon="askAce" color={hoverEl === 'askAce' ? theme.palette.primary.main : theme.customText.icon_high} />
          }
          action={
            <StyledButton
              variant="contained"
              fullWidth
              color={hoverEl === 'askAce' ? 'primary' : 'secondary'}
              data-testid="btnCreateCalculation"
              style={{
                flexDirection: 'column',
                textTransform: 'none',
                padding: 5
              }}
            >
              <Text style={{ padding: 0, color: 'inherit' }} variant="h5">
                Ask Ace
              </Text>
              <Text
                style={{ padding: 0, fontWeight: 400, color: 'inherit' }}
                variant="smallRegular"
              >
                Accurate, Helpful, Intelligent
              </Text>
            </StyledButton>
          }
          selected={hoverEl === 'askAce'}
          onMouseEnter={() => setHoverEl('askAce')}
          onMouseLeave={handleMouseLeave}
        />
      )}
      {/REKAVA_ADMIN|COMPOSER_ADMIN|COMPOSER|PRINCIPAL/.test(
        String(userRole)
      ) && (
          <StyledCard
            height={264}
            onClick={handelOnclickCreateCalc}
            content={
              hoverEl === 'create' ? (
                <img src={images.filePlusHover} alt="icon" />
              ) : (
                <img src={images.filePlus} alt="icon" />
              )
            }
            action={
              <StyledButton
                variant="contained"
                fullWidth
                color={hoverEl === 'create' ? 'primary' : 'secondary'}
                data-testid="btnCreateCalculation"
                style={{
                  flexDirection: 'column',
                  textTransform: 'none',
                  padding: 5
                }}
              >
                <Text style={{ padding: 0, color: 'inherit' }} variant="h5">
                  Create a Calculation
                </Text>
                <Text
                  style={{ padding: 0, fontWeight: 400, color: 'inherit' }}
                  variant="smallRegular"
                >
                  Compose, Log, Report
                </Text>
              </StyledButton>
            }
            selected={hoverEl === 'create'}
            onMouseEnter={() => setHoverEl('create')}
            onMouseLeave={handleMouseLeave}
          />
        )}
      <StyledCard
        height={264}
        onClick={goToEnterprise}
        content={
          hoverEl === 'my-calculation' ? (
            <img src={images.folderHover} alt="icon" />
          ) : (
            <img src={images.folder} alt="icon" />
          )
        }
        action={
          <StyledButton
            variant="contained"
            fullWidth
            color={hoverEl === 'my-calculation' ? 'primary' : 'secondary'}
            data-testid="btnGoToCalculation"
            style={{
              flexDirection: 'column',
              textTransform: 'none',
              padding: 5
            }}
          >
            <Text style={{ padding: 0, color: 'inherit' }} variant="h5">
              Go to Calculations
            </Text>
            <Text
              style={{ padding: 0, fontWeight: 400, color: 'inherit' }}
              variant="smallRegular"
            >
              Store, View, Manage
            </Text>
          </StyledButton>
        }
        selected={hoverEl === 'my-calculation'}
        onMouseEnter={() => setHoverEl('my-calculation')}
        onMouseLeave={handleMouseLeave}
      />
      {openModalAction && (
        <ModalAction openModal={true} submitModal={submitModal} />
      )}
      <HelperButton />
    </>
  );

  const renderWarningMobilePage = () => (
    <div>
      <div style={{ textAlign: 'center', justifyContent: 'center' }}>
        <img
          width={88}
          src={images.spotMobile}
          alt="spot-logo"
          loading="lazy"
        />
        <Text variant="body1" style={{ padding: '20px 0' }}>
          Open in Desktop for Better Experience
        </Text>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: 10 }}>
          <StyledButton
            type="button"
            variant="outlined"
            style={{ width: 168, height: 48, paddingRight: 12 }}
            onClick={() => handleLogout()}
          >
            Logout
          </StyledButton>
        </div>
        <div>
          <StyledButton
            type="button"
            variant="contained"
            style={{ width: 168, height: 48 }}
            onClick={() => handleCont()}
          >
            Continue to App
          </StyledButton>
        </div>
      </div>
    </div>
  );

  const handleClickAnnouncement = useCallback(() => {
    if (
      (!!subscription &&
        !((subscription as Record<string, unknown>)?.plan as string).includes(SubscriptionType.ACE)
      )
    ) {
      dispatch(formHelperPanel(true, HelperPanelType.CONVERT_ACE));
    } else {
      history.push('/ace/public')
    }
  }, []);

  return (
    <>
      {!!subscription && !((subscription as Record<string, unknown>)?.plan as string).includes(SubscriptionType.ACE) && <AnnouncementBar buttonText={buttonText} onClick={handleClickAnnouncement} />}
      <DashboardContainer>
        {isMobile && renderWarningMobilePage()}
        {!isMobile && renderDashboardPage()}
      </DashboardContainer>
    </>
  );
}
