import * as actions from "./type";

import {
  getCommunityWorkspaceId,
  getPersonalWorkspaceId,
  getUserProfile,
} from "../../../utils/storage";

export interface SiderbarStateI {
  folderTree: Array<any>;
  selectedItem: string;
}

const STORAGE_KEY = "sidebar";

export const TreeLogo = {
  PEOPLE: "people",
  FOLDER: "folder",
  COMMUNITY: "community",
  HELP: "help",
  TEAMDRIVES: "businessDrives",
  ASKACE: "askAce",
  ACEPUBLIC: "acePublic",
  ACEENTERPRISE: "aceInterprise"
};

export let initialState: SiderbarStateI = {
  folderTree: [],
  selectedItem: "",
};

export interface FolderTreeItem {
  id: string;
  title: string;
  isExpanded: boolean;
  children: any;
  icon?: string;
  dataTestid?: string;
}

const sidebarReducer = (state: SiderbarStateI = initialState, action: any) => {
  switch (action.type) {
    case actions.INIT_TREE_STATE:
      const data = localStorage.getItem(STORAGE_KEY);
      initialState = {
        folderTree: action.userType === 'REGULAR' ? [
          {
            id: getPersonalWorkspaceId(),
            title: "My Calculations",
            isExpanded: false,
            icon: TreeLogo.FOLDER,
            children: null,
            dataTestid: "menuMyCalculation",
          },
          {
            id: getCommunityWorkspaceId(),
            title: "Community Calculations",
            isExpanded: false,
            icon: TreeLogo.COMMUNITY,
            children: null,
            dataTestid: "menuCommunityCalculation",
          },
          // KPD-1526 hide enterprise feature
          // {
          //   id: getEnterpriseWorkspaceId(),
          //   title: "Enterprise Calculation",
          //   isExpanded: false,
          //   icon: TreeLogo.FOLDER,
          //   children: [],
          //   dataTestid: "menuEnterpriseCalculation",
          // },
          {
            id: "businessDrives",
            title: "Business Drive",
            isExpanded: false,
            icon: TreeLogo.TEAMDRIVES,
            children: [],
            dataTestid: "businessDrives",
          },

          {
            id: "helpPage",
            title: "Help",
            isExpanded: false,
            icon: TreeLogo.HELP,
            children: [],
            dataTestid: "helpPage",
          },
        ] : [
          {
            id: "aceEnterprise",
            title: "Ace Enterprise",
            isExpanded: false,
            icon: TreeLogo.ACEENTERPRISE,
            children: [],
            dataTestid: "aceEnterprise",
          },
        ],
        selectedItem: action.userType === 'REGULAR' ? getPersonalWorkspaceId() ?? ""
          : action.pathname === '/ace/enterprise' ? "aceEnterprise"
            : action.pathname === '/ace/public' ? "acePublic" : "",
      };

      const { folderTree, selectedItem } = initialState;

      const userRole = getUserProfile();
      const checkNotChecker = /REKAVA_ADMIN|COMPOSER_ADMIN/.test(
        String(userRole)
      );
      const hasAccountManager = findNode(folderTree, "account");

      if (checkNotChecker) {
        if (!hasAccountManager) {
          action.userType === 'REGULAR' ?
            folderTree.unshift(
              {
                id: "account",
                title: "Account Manager",
                isExpanded: false,
                icon: TreeLogo.PEOPLE,
                children: [],
              }, {
              id: "acePublic",
              title: "Ace Public",
              isExpanded: false,
              icon: TreeLogo.ACEPUBLIC,
              children: [],
              dataTestid: "acePublic",
            }, {
              id: "aceEnterprise",
              title: "Ace Enterprise",
              isExpanded: false,
              icon: TreeLogo.ACEENTERPRISE,
              children: [],
              dataTestid: "aceEnterprise",
            }) :
            folderTree.unshift(
              {
                id: "account",
                title: "Account Manager",
                isExpanded: false,
                icon: TreeLogo.PEOPLE,
                children: [],
              });
        }
      } else {
        if (hasAccountManager) {
          delete folderTree[0];
        }
      }

      return { folderTree, selectedItem };

    case actions.GET_TREE_CHILD_SUCCESS:
      const tree = action.items.map((item: any): FolderTreeItem => {
        const { id, title, dataTestid } = item;
        return {
          id,
          title,
          isExpanded: false,
          children: null,
          icon: TreeLogo.FOLDER,
          dataTestid,
        };
      });

      const updatedState = {
        ...state,
        folderTree: addNode(state.folderTree, state.selectedItem, tree),
      };

      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedState));

      return updatedState;

    case actions.GET_TREE_CHILD_VIEW_SUCCESS:
      const selectedNode = sessionStorage.getItem('expanded_tree_node') || '';
      const treeView = action.items.map((item: any): FolderTreeItem => {
        const { id, title, dataTestid } = item;
        return {
          id,
          title,
          isExpanded: false,
          children: null,
          icon: TreeLogo.FOLDER,
          dataTestid,
        };
      });

      const updatedStateView = {
        ...state,
        folderTree: addNode(state.folderTree, selectedNode, treeView),
      };

      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedStateView));

      return updatedStateView;

    case actions.SET_SELECTED_TREE_ITEM:
      const newState = {
        ...state,
        selectedItem: action.id,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
      return newState;

    case actions.UPDATE_TREE_STATE:
      const updated = {
        ...state,
        folderTree: updatedNodeState(
          state.folderTree,
          action.id,
          action.newState
        ),
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
      return updated;

    case actions.GET_TREE_CHILD:
    case actions.GET_TREE_CHILD_FAILED:
    default:
      return state;
  }
};

function addNode(folderTree: any, id: string, children: any) {
  folderTree.map((item: any) => {
    if (item.id == id) {
      item.children = children;
      return item;
    }
    if (item.children) {
      return addNode(item.children, id, children);
    }
  });
  return folderTree;
}

// check if node already exists in tree
export function findNode(folderTree: any, id: string) {
  let found = false;
  folderTree.forEach((item: any) => {
    if (item.id == id) {
      found = item.children != null;
      return;
    }
    if (item.children) return findNode(item.children, id);
  });
  return found;
}

export function updatedNodeState(folderTree: any, id: string, newState: any) {
  folderTree.forEach((item: any, idx: any) => {
    if (item.id == id) {
      const temp = Object.assign({}, item, newState);
      folderTree[idx] = temp;
    } else if (item.children) {
      return updatedNodeState(item.children, id, newState);
    }
  });
  return folderTree;
}

export default sidebarReducer;
