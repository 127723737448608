import { CLOSEEVENTTYPEPOPUP, DATAFORMMODALTYPEPOPUP } from "./formModalType";
import {
  SaveAsInputFile,
  clearRecentInputFile,
  refreshInputFile,
  renameInputFile,
  savingInputFileToApi,
} from "../../../actions/loggerAction";
import {
  activateUser,
  addBillingMember,
  changeBillingPaymentStatus,
  deactivateUser,
  editUser,
  fetchAllUsers,
  getUserCalculationLimit,
  handleChangeUserPlan,
  isActiveUser,
  postAddUser,
  resetPassword,
} from "../../../actions/userAction";
import {
  addFolder,
  deleteFolder,
  moveFolder,
  renameFolder,
} from "../../../actions/folderActions";
import {
  calIdAction,
  calInfoDelete,
  calInfoMultipleDelete,
  calInfoRename,
} from "../../../actions/calInfoAction";
import {
  calculationDelete,
  createNewCalc,
  editCalcInfo,
  makeCopyCal,
  makeMultipleCopy,
  moveCal,
  moveMultipleCal,
} from "../../../actions/calculationsActions";
import {
  checkSubscriptionAndDate,
  checkSubscriptionMultipleSelect,
  convertLatex,
  insertLatex,
  replaceMark,
} from "../../../../utils/helpersFunction";
import { createTeamDrive, fetchTeamDrives, renameTeamDrive, setActionReport, submitLoading, uploadLoggerPreview } from "../../global/globalActions";
import { deleteFile, moveFile, renameFile } from "../../../actions/fileActions";
import {
  deleteReport,
  fetchCalculationReportList,
  renameReport,
  saveReport,
} from "../../../actions/reportAction";
import { fetchCalculationInputFileList, inputFileInfoDelete } from "../../../actions/inputFileAction";
import { getPersonalWorkspaceId, getUserDetails, setPreventChangesCalculation, setUserDetails } from "../../../../utils/storage";
// IMPORTING ANOTHER REDUX ACTION
import {
  reducerModifierActivePageActionComposer,
  reducerModifierDataBodyOfCalculationActionComposer,
  reducerModifierDataHeadOfCalculationActionComposer,
  reducerModifierDataPagesActionComposer,
} from "../../../composer/composerPage/composerActions";
import { saveAsInputFile, saveInputFile } from "../../../actions/logger.actions";
import {
  setActionState,
  setCalculationTarget,
  setPreventChanges,
} from "../../../composer/composerChanges/composerChangesActions";

import { AnyAction } from "redux";
import { CalculationI } from "../../../types/calculationsTypes";
import CalculationService from "../../../../service/CalculationServices";
import UserService, { CompanyInterface } from "../../../../service/UserService";
// IMPORTING SERVICE
import InputFileService from "../../../../service/InputFileService";
import { RenameFolderPayloadI } from "../../../../pages/businessDrives/businessDrives.types";
import Service from "../../../../service/generic/generic";
import { ThunkAction } from "redux-thunk";
import { actionReport } from "../../../types/reportTypes";
import { addTableData } from "../../../../utils/tableFunction";
import { changePositionActionCircleatorBreadcrumb } from "../../../breadCrumb/circleator/circleatorAction";
import { encalDataAction } from "../../../actions/encalActions";
import images from "../../../../assets";
import { setChangePlan } from "../../../types/userTypes";
// IMPORTING ANOTHER REDUX ACTION
import { toastSetupActionPopUp } from "../toast/toastAction";
import { updateGradeAccountAction } from "../../../actions/loginActions";
import { updateUserList } from "../../../types/userTypes";
import { validateDecimalChanges } from "../../../../pages/loggerRefactor/utils";
import AceServices from "../../../../service/AceService";
import { fetchChatSessions, generateChatSessionId, setChatSessionsPage, setFetching, setSessionId, successAction } from "../../../actions/aceActions";
import { ChatSessionI } from "../../../../pages/askAce/askAce.type";
import { getSessionId } from "../../../../utils/reduxOutside";

let service: any;

export function reducerModifierCloseEventActionPopUp(data: boolean) {
  return { type: CLOSEEVENTTYPEPOPUP, data };
}

export function reducerModifierDataFormModalActionPopUp(data: any) {
  return { type: DATAFORMMODALTYPEPOPUP, data };
}

export const contentNull: any = {
  head: null,
  body: [],
  foot: [],
  storage: null,
};

// FORM MODAL SETUP

export function formModalSetupActionPopUp(type: string, payload?: any) {
  return async (dispatch: any, getState: any) => {
    // REDUCER STATE LIBRARY, PLEASE USE SUPER DECENT VARIABLE
    const dataHeadOfCalculation: any =
      getState().composer.dataHeadOfCalculation;
    const calInfo: any = getState().calInfo;
    const userDetails: any = getState().login.userDetails;
    const workspace: any = getState().global.workspace;
    // VARIABLE DECLARATION
    let backendResponse: any;
    switch (type) {
      // PLEASE USE ENTER FOR GAP, THANK YOU FROM FARANDI
      case "CLOSE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: false,
            type: null,
            content: contentNull,
          })
        );
        break;

      case "EDIT_CALCULATION_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Edit Calculation Info",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: dataHeadOfCalculation.title,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Description",
                  limit: 140,
                  defaultValue: dataHeadOfCalculation.description,
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "createdBy",
                  placeholder: "Created By",
                  limit: null,
                  defaultValue: dataHeadOfCalculation.createdBy,
                  isDisabled: true,
                },
                // KPD-1764 Composer View - Workpage - Edit Calculation Icon - Hide Feature - Principal
                // {
                //   type: "SELECT",
                //   name: "principal",
                //   placeholder: "Principal",
                //   value: null,
                //   contentValue: ["Reza", "Didit", "Kurniawan", "Andhika"],
                //   dataTestid: "dropdownPrincipal",
                // },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "UPDATE",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Publish",
              body: [
                {
                  type: "LABEL",
                  label:
                    "Your calculations will be published to the Enterprise Calculation, are you sure you want to publish??",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "PUBLISH",
                },
              ],
              storage: {
                idCalculation: payload.idCalculation,
                location: payload.location,
              },
            },
          })
        );
        break;

      case "PUBLISH_TO_COMMUNITY_CALCULATION_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Publish",
              body: [
                {
                  type: "LABEL",
                  label:
                    "Your calculations will be published to the Community Calculation, are you sure you want to publish??",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "PUBLISH",
                },
              ],
              storage: {
                idCalculation: payload.idCalculation,
                location: payload.location,
              },
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_PAGE_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete Page",
              body: [
                {
                  type: "LABEL",
                  label: `Are you sure want to delete ${payload.pageName}?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case 'CONFIRMATION_SHOWTOLOGGER_PAGE_FROM_COMPOSER':
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Hide Page for Log View?",
              body: [
                {
                  type: "LABEL",
                  label: `Please make sure there is no input component in the hidden page.`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "OK",
                },
              ],
              storage: {
                idCalculation: payload.idCalculation,
              },
            },
          })
        );
        break;

      case "NEW_CALCULATION_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Description",
                  limit: 140,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "createdBy",
                  placeholder: "Created By",
                  limit: null,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
                // {
                //   type: "SELECT",
                //   name: "principal",
                //   placeholder: "Principal",
                //   value: null,
                //   contentValue: ["Reza", "Didit", "Kurniawan", "Andhika"],
                //   dataTestid: "dropdownPrincipal",
                // },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CREATE",
                  dataTestid: "btnCreate",
                },
              ],
              storage: {
                folderIdCalculation: payload.folderIdCalculation,
                loggerPreview: Boolean(payload.loggerPreview)
              },
            },
          })
        );
        break;

      case "SAVE_AS_FROM_COMPOSER":
        service = new Service("folders");
        backendResponse = await service.getSubresource(
          payload.folderIdCalculation,
          "view?type=FOLDER&isActive=true"
        );

        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save to",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "BIGPATH",
                  name: "folder",
                  currentValue: {
                    id: backendResponse.data.id,
                    title: backendResponse.data.title,
                    items: backendResponse.data.items,
                  },
                  parentId: backendResponse.data.parentId,
                  dataTestid: "dropdownSaveAsTo",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CREATE",
                  dataTestid: "btnCreate",
                },
              ],
              storage: {
                idCalculation: payload.idCalculation,
                folderIdCalculation: payload.folderIdCalculation,
              },
            },
          })
        );
        break;

      case "EXIT_CALCULATION_FROM_COMPOSER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Document?",
              body: [
                {
                  type: "LABEL",
                  label:
                    "Save changes to Calculation Name before closing? Your Changes will be lost if you don't save them",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                  dataTestid: "btnSave",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete Calculation",
              body: [
                {
                  type: "LABEL",
                  label: `${payload.calName} have data in the input file. Are you sure to delete
                this file?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_MULTIPLE_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete Calculation",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Are you sure you want to delete this files?`,
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "DANGER",
                  label: "DELETE",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "RENAME_CALCULATION_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Title",
                  limit: 60,
                  defaultValue: calInfo.calName,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "RENAME_CALCULATION_FROM_DRIVE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: payload?.data.title,
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "MAKE_COPY_CALCULATION_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Calculation Name",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "MAKE_COPY_CALCULATION_FROM_DRIVE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Duplicate Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;


      case "PUBLISH_MULTIPLE_CALCULATIONS":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: `Copy to ${payload.text} `,
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Copy files to ${payload.text}?`,
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "OK",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "EDIT_CALCULATION_INFO_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Edit Calculation Info",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Title",
                  limit: 60,
                  defaultValue: calInfo.calName,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Calculation Description",
                  limit: 140,
                  defaultValue: calInfo.calDescription,
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "userDetails",
                  placeholder: "User Detail",
                  limit: 20,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
                // KPD-1763 Calculation Manager - Calculation Info Panel - Edit Calculation Icon - Hide Feature - Principal
                // {
                //   type: "SELECT",
                //   name: "principal",
                //   defaultValue: "Principal",
                //   value: null,
                //   contentValue: ["Reza", "Didit", "Kurniawan", "Andhika"],
                //   dataTestid: "dropdownPrincipal",
                // },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "EDIT_CALCULATION_INFO_FROM_DRIVE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Edit Calculation Info",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: calInfo.calName,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Calculation Description",
                  limit: 140,
                  defaultValue: calInfo.calDescription,
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "userDetails",
                  placeholder: "User Detail",
                  limit: 20,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "RENAME_FOLDER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename Folder",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Folder Name",
                  limit: 60,
                  defaultValue: payload?.title,
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                },
              ],
              storage: payload.type === 'TEAM' ? payload : payload.type,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_FOLDER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: payload.type === 'TEAM' ? "Delete" : "Delete Folder",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `This ${payload.title} will be deleted. Are you sure to delete this folder?`,
                  labelAlign: "center"
                },
              ],
              foot: [
                {
                  type: payload.type === 'TEAM' ? "CANCEL_OUTLINED" : "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: payload.type === 'TEAM' ? "DANGER" : "SUBMIT",
                  label: payload.type === 'TEAM' ? "DELETE" : "CONFIRM",
                },
              ],
              storage: payload.type === 'TEAM' ? payload : payload.type,
            },
          })
        );
        break;

      case "ADD_FOLDER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Add Folder",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Folder Name",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputFolderName",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CREATE_CALCULATION_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create Calculation",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Title",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Calculation Description",
                  limit: 140,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "userDetails",
                  placeholder: "User Detail",
                  limit: 20,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
                {
                  type: "SELECT",
                  name: "principal",
                  defaultValue: "Principal",
                  value: null,
                  contentValue: ["Reza", "Didit", "Kurniawan", "Andhika"],
                  dataTestid: "dropdownPrincipal",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "RENAME_INPUT_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename Input File",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Input File Name",
                  limit: 60,
                  defaultValue: payload.title,
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "MOVE_CALCULATION":
        service = new Service("folders");
        backendResponse = await service.getSubresource(
          workspace,
          "view?type=FOLDER&isActive=true"
        );
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Move to",
              headAlign: "center",
              body: [
                {
                  type: "BIGPATH",
                  name: "folder",
                  currentValue: {
                    id: backendResponse.data.id,
                    title: backendResponse.data.title,
                    items: backendResponse.data.items,
                  },
                  parentId: backendResponse.data.parentId,
                  dataTestid: "dropdownMoveToCalculation",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "SELECT",
                  dataTestid: "btnSelectMove",
                },
              ],
              storage: {
                idCalculation: payload.calId,
                folderIdCalculation: payload.id,
              },
            },
          })
        );
        break;

      case "MOVE_MULTIPLE_CALCULATION":
        service = new Service("folders");
        backendResponse = await service.getSubresource(
          workspace,
          "view?type=FOLDER&isActive=true"
        );
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Move to",
              headAlign: "center",
              body: [
                {
                  type: "BIGPATH",
                  name: "folder",
                  currentValue: {
                    id: backendResponse.data.id,
                    title: backendResponse.data.title,
                    items: backendResponse.data.items,
                  },
                  parentId: backendResponse.data.parentId,
                  dataTestid: "dropdownMoveToCalculation",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "SELECT",
                  dataTestid: "btnSelectMove",
                },
              ],
              storage: {
                folderIdCalculation: payload.calInfo.id,
                activeIds: payload.activeIds
              },
            },
          })
        );
        break;

      case "MOVE_FOLDER":
        service = new Service("folders");
        backendResponse = await service.getSubresource(
          workspace,
          "view?type=FOLDER&isActive=true"
        );

        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Move to",
              headAlign: "center",
              body: [
                {
                  type: "BIGPATH",
                  name: "folder",
                  currentValue: {
                    id: backendResponse.data.id,
                    title: backendResponse.data.title,
                    items: backendResponse.data.items.filter(
                      (item: any) => item?.id !== payload?.id
                    ),
                  },
                  parentId: backendResponse.data.parentId,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SELECT",
                },
              ],
              storage: {
                folderId: payload.id,
                folderWorkspace: workspace,
                folderName: payload.title,
              },
            },
          })
        );
        break;

      case "CONFIRMATION_PRINT_VIEW_TO_REPORT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "SAVE REPORT?",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Save report before closing? Your changes will be lost if you
                don't save them`,
                  labelAlign: "center"
                },
              ],
              foot: [
                {
                  dataTestid: "btnCancel",
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  followup: true,
                },
                {
                  dataTestid: "btnSave",
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete File",
              body: [
                {
                  type: "LABEL",
                  label: `This ${payload.title} will be deleted. Are you sure to delete this file?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "RENAME_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename File",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "File Name",
                  limit: 20,
                  defaultValue: payload?.title?.replace(/\.[^/.]+$/, ""),
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "CONFIRM",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      case "MOVE_FILE":
        service = new Service("folders");
        backendResponse = await service.getSubresource(
          workspace,
          "view?type=FOLDER&isActive=true"
        );

        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Move to",
              headAlign: "center",
              body: [
                {
                  type: "BIGPATH",
                  name: "folder",
                  currentValue: {
                    id: backendResponse.data.id,
                    title: backendResponse.data.title,
                    items: backendResponse.data.items,
                  },
                  parentId: backendResponse.data.parentId,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SELECT",
                },
              ],
              storage: {
                fileId: payload.id,
                folderWorkspace: workspace,
                fileName: payload.title,
              },
            },
          })
        );
        break;

      case "RENAME_REPORT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename Report",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Report Name",
                  limit: 60,
                  defaultValue: payload.reportTitle,
                  isDisabled: false,
                  dataTestid: "inputFileName",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: {
                reportId: payload.reportId,
                reportTitle: payload.reportTitle,
                calculationId: payload.calculationId,
              },
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_REPORT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete Report",
              body: [
                {
                  type: "LABEL",
                  label: `${payload.title} will be deleted permanently. 
                      Are you sure to delete this report`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "DELETE",
                  dataTestid: "btnDeleteFile",
                },
              ],
              storage: {
                reportId: payload.reportId,
                reportTitle: payload.reportTitle,
                calculationId: payload.calculationId,
              },
            },
          })
        );
        break;

      case "OPEN_OTHER_REPORT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Open Report",
              headAlign: "center",
              body: [
                {
                  type: "SELECT",
                  name: "report",
                  placeholder: "Select Reports",
                  value: null,
                  createNew: false,
                  contentValue: payload.data.map(
                    (a: any) => a.title
                  ),
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "START",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Publish",
              body: [
                {
                  type: "LABEL",
                  label: `Your calculations will be published to the Enterprise Calculation, are you sure you want to publish?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "PUBLISH",
                },
              ],
              storage: {
                location: payload.location,
              },
            },
          })
        );
        break;

      case "PUBLISH_TO_COMMUNITY_CALCULATION_FROM_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Publish",
              body: [
                {
                  type: "LABEL",
                  label: `Your calculations will be published to the Community Calculation, are you sure you want to publish?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "PUBLISH",
                },
              ],
              storage: {
                location: payload.location,
              },
            },
          })
        );
        break;

      case "COPY_TO_MYCALC_FROM_ENTERPRISE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Copy to my calculation",
              body: [
                {
                  type: "LABEL",
                  label: `Select the version you want to copy to My Calculation`,
                },
                {
                  type: "SELECT",
                  name: "version",
                  placeholder: "Version",
                  value: null,
                  contentValue: payload.data.dataCalculation.version.map(
                    (a: any) => a.versionName
                  ),
                  dataTestid: "dropdownVersionsCopyToMyCalc",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "OK",
                  dataTestid: "btnOk",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "SAVE_CONFIRMATION_LOGGER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Input File",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `You are about to proceed to Print View. You can click the "Save" button to keep your inputs for next time`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "No",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_COMMUNITY":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete File",
              body: [
                {
                  type: "LABEL",
                  label: `Do you want delete the calculation?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "DELETE",
                },
              ],
              storage: {
                payload,
              },
            },
          })
        );
        break;

      case "COPY_TO_MYCALC_FROM_COMMUNITY":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Copy to my calculation",
              body: [
                {
                  type: "LABEL",
                  label: "Copy this file to my calculation?",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "OK",
                  dataTestid: "btnOk",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "ADD_USER_USER_MANAGEMENT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Add User",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "name",
                  placeholder: "Name",
                  limit: 30,
                  defaultValue: "",
                  isDisabled: false,
                },
                {
                  type: "TEXT",
                  name: "email",
                  placeholder: "Email",
                  defaultValue: "",
                  isDisabled: false,
                },
                {
                  type: "TEXT",
                  name: "Organization/Institution",
                  placeholder: "Organization/Institution",
                  defaultValue: '',
                  limit: 250,
                  isDisabled: false,
                },
                {
                  type: "SELECT",
                  name: "company",
                  defaultValue: "Company",
                  value: null,
                  contentValue: payload.companies.map((item: CompanyInterface) => item.title),
                  dataTestid: "dropdownCompany",
                  label: "Company"
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "ADD",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "FORGOT_PASSWORD":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Forgot Password?",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `We will send you a link to change your password via email`,
                  labelAlign: 'center'
                },
                {
                  type: "TEXT",
                  name: "email",
                  placeholder: "Email",
                  defaultValue: "",
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "RECOVER",
                },
              ],
              storage: '',
            },
          })
        );
        break;

      case "UPGRADE_PLAN_SUBSCRIPTION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Upgrade Pro",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Get all the feature of Rekava without any limitations.`,
                  labelAlign: 'center'
                },
                {
                  type: "IMAGE",
                  image: payload.image,
                  text: "What's included",
                  listItem: ["All calculation features", "Unlimited calculation files", "Premium support", "Up to 10 team members"]
                },
              ],
              foot: [
                {
                  type: "SUBMIT",
                  label: "Upgrade Pro",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "UPGRADE_PLANS_POPUP":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: `Your subscription is on the ${payload.pretty} Plan. Upgrade to collaborate with more people using Rekava.`,
              headAlign: 'center',
              body: [],
              foot: [],
              upgrade: true,
              storage: payload?.plan,
            },
          })
        );
        break;
      case "UPGRADE_PLAN_SUBSCRIPTION_PRO":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            followUp: true,
            type: type,
            content: {
              head: "Upgrade Pro",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Get all the feature of Rekava without any limitations.`,
                  labelAlign: 'center'
                },
                {
                  type: "IMAGE",
                  text: "What's included",
                  listItem: ["All calculation features", "Unlimited calculation files", "Premium support", "Up to 10 team members"],
                  textButton: 'And more ...',
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "More Info",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Upgrade Pro",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "UPGRADE_SUCCESS_SUBSCRIPTION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              body: [
                {
                  type: "IMAGE_STATIC",
                  image: payload.image,
                },
                {
                  type: "LABEL",
                  boldLabel: "Succesfully Upgraded",
                  label: `Congratulation you can access all the features.`,
                  labelAlign: 'center'
                },
              ],
              storage: '',
            },
          })
        );
        break;

      case "RESET_PASSWORD_ACCOUNT_MANAGER":
        await replaceMark(payload);
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Reset Password",
              body: [
                {
                  type: "LABEL",
                  label: `Do you want to reset user's password? New Password will send to user email.`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "RESET",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "DEACTIVATE_ACCOUNT_MANAGER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Deactivate",
              body: [
                {
                  type: "LABEL",
                  label: `Do you want to deactivate this account?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "DANGER",
                  label: "DEACTIVATE",
                },
              ],
              storage: payload.id,
            },
          })
        );
        break;

      case "ACTIVATE_USER_ACCOUNT_MANAGER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Activate",
              body: [
                {
                  type: "LABEL",
                  label: `Do you want to activate this account?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "ACTIVATE",
                },
              ],
              storage: payload.id,
            },
          })
        );
        break;
      case "EDIT_CALCULATION_BUSINESS_ON_EDIT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Edit Calculation",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `There are other members who are editing the calculations. Please wait or make a new calculation.`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "Create New",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "SAVE_REPORT_PRINT_VIEW":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Report",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Are you sure you want to save ${payload.title}?`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "YES",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "SAVE_REPORT_PRINT_VIEW_BREADCRUMB":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Report",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `You are about to leave Print View.`,
                  subLabel: `Do you want to save `,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "No",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "EDIT_ACCOUNT_MANAGER":
        await replaceMark(payload.activeItem);
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Edit User",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "name",
                  placeholder: "Name",
                  value: "",
                  limit: 30,
                  defaultValue: payload.activeItem.name,
                  isDisabled: false,
                },
                {
                  type: "TEXT",
                  name: "email",
                  placeholder: "Email Address",
                  defaultValue: payload.activeItem.email,
                  limit: 30,
                  isDisabled: true,
                },
                {
                  type: "TEXT",
                  name: "Organization/Institution",
                  placeholder: "Organization/Institution",
                  defaultValue: payload.activeItem.organization,
                  limit: 250,
                  isDisabled: false,
                },
                {
                  type: "SELECT",
                  name: "company",
                  defaultValue: "Company",
                  value: payload.res.data.find((item: CompanyInterface) => item.id === payload.activeItem.companyId)?.title ?? '',
                  contentValue: payload.res.data.map((item: CompanyInterface) => item.title),
                  dataTestid: "dropdownCompany",
                  label: "Company"
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "LEAVE_REPORT_PRINT_VIEW":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Back to Logger View",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `You are about to leave Print View.`,
                  subLabel: `Do you want to save `,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "No",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "HANDLE_CHANGE_PLANS":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Change Plan",
              headAlign: "center",
              body: [
                {
                  type: "SELECT",
                  name: "plan",
                  placeholder: "Plan",
                  value: payload.subscriptionUser?.plan,
                  contentValue: payload.subscriptionList,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Change",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CHANGE_BILLING_PAYMENT_STATUS":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Change Payment Status",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                },
                {
                  type: "SELECT",
                  name: "status",
                  placeholder: "Status",
                  value: payload[0].paymentStatusPretty,
                  contentValue: ["Waiting for Payment", "Paid", "Failed"],
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "ADD_BILLING_MEMBER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Add Member",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "email",
                  placeholder: "Email",
                  limit: 50,
                  value: null,
                  isDisabled: false
                },
                {
                  type: "SELECT",
                  name: "plan",
                  placeholder: "Plan",
                  value: null,
                  contentValue: ["Pro", "Pro Plus", "Business", "Business Plus", "Enterprise"],
                },
                {
                  type: "NUMBER",
                  name: "invoice",
                  placeholder: "Total Invoice (IDR)",
                  limit: 30,
                  defaultValue: 0,
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRM_ADD_BILLING_MEMBER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Information",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `This user have paid billing personally. You will take over their next bill with a pro rate system.`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Got it",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "START_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "To Start Logging Your Calculation, Select Existing or Create a New Input File",
              headAlign: "center",
              body: [
                {
                  type: "SELECT",
                  name: "inputFile",
                  placeholder: "Select file or create new",
                  value: null,
                  createNew: true,
                  contentValue: payload.inputFileList.map(
                    (a: any) => a.title
                  ),
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "START",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "COMPOSER_DATA_CHANGES_CONFIRMATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Editing a calculation that has Input Files?",
              headAlign: "center",
              upSize: "true",
              body: [
                {
                  type: "LABEL",
                  label: payload?.calculation?.folderType === 'TEAM' ?
                    "In order to avoid problems with mismatching inputs and input fields, you'll need create a new calculation"
                    : "In order to avoid problems with mismatching inputs and input fields, you'll need to either delete all input files associated with this calculation, or create a completely new calculation",
                  labelAlign: "center",
                },
              ],
              foot: [
                payload?.calculation?.folderType !== 'TEAM' ? {
                  type: "CANCEL_OUTLINED",
                  label: "Delete all Input Files",
                  followup: true,
                } : {},
                {
                  type: "SUBMIT",
                  label: "Create a New Calculation",
                }
              ],
              storage: payload,
            },
          })
        );
        break;

      case "COMPOSER_DATA_CHANGES_NEW_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create a new calculation?",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `You will create a new calculation with this changes`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Create",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Description",
                  limit: 140,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "userDetails",
                  placeholder: 'Created By',
                  limit: 20,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
                // {
                //   type: "SELECT",
                //   name: "principal",
                //   defaultValue: "Principal",
                //   value: null,
                //   contentValue: ["Reza", "Didit", "Kurniawan", "Andhika"],
                //   dataTestid: "dropdownPrincipal",
                // },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CREATE",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CREATE_INPUT_FILE_FROM_COMPOSER_NAVBAR":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Start Calculation",
              headAlign: "center",
              body: [
                {
                  type: "SELECT",
                  name: "createNew",
                  placeholder: "Create New Input File",
                  value: null,
                  createNew: true,
                  contentValue: payload.inputFileList.map(
                    (a: any) => a.title
                  ),
                  isDisabled: true,
                },
                {
                  type: "TEXT",
                  name: "inputFile",
                  placeholder: "Input File Name",
                  limit: 20,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                  inputPlaceHolder: true,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "START",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "EXIT_LOGGER_TO_INPUT_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Exit to Input File Manager",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Save changes before exit?`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "Exit Without Save",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "Save & Exit",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "SAVE_CONFIRMATION_LOGGER_CHANGES":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Input File",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `Save change to ${payload.title} before closing? Your changes will be lost if you don't change them`,

                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "No",
                  followup: true
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "EDIT_EMAIL_ACCOUNT_SETTING":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Change Email",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `We will send a validation message to your new email address. Please click the "Confirm email" button inside to complete the change.`,
                  labelAlign: "center",
                },
                {
                  type: "TEXT",
                  name: "email",
                  placeholder: "New Email",
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "input",
                },
                {
                  type: "PASSWORD",
                  name: "password",
                  placeholder: "Confirm Password",
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "input",
                },
              ],
              textButton: {
                label: 'Forgot Password',
                onClick: payload,
              },
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "EDIT_PASSWORD_ACCOUNT_SETTING":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Change Password",
              headAlign: "center",
              body: [
                {
                  type: "PASSWORD",
                  name: "currentPassword",
                  placeholder: "Current Password",
                  limit: 8,
                  isDisabled: false,
                  dataTestid: "inputFileName",
                },
                {
                  type: "PASSWORD",
                  name: "newPassword",
                  placeholder: "New Password",
                  limit: 8,
                  isDisabled: false,
                  dataTestid: "inputFileName",
                },
                {
                  type: "PASSWORD",
                  name: "confirmNewPassword",
                  placeholder: "Confirm New Password",
                  limit: 8,
                  isDisabled: false,
                  dataTestid: "inputFileName",
                },
              ],
              textButton: {
                label: 'Forgot Password',
                onClick: payload,
              },
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: null
            },
          })
        );
        break;
      case "SAVE_REPORT_PRINT_VIEW_CONFIRMATION_TO_MYCALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Report",
              body: [
                {
                  type: "LABEL",
                  label: `Are you sure you want to save ${payload.title}?`,
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "YES",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_INPUT_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: payload.noOfReports > 0 ? `This Input File has report files. Are you sure to delete this file?` : `Are you sure to delete this file?`,
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  dataTestid: "btnCancel",
                },
                {
                  type: "DANGER",
                  label: "Delete",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "PREVIEW_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: payload.title,
              body: [
                {
                  type: "LABEL",
                  label: payload.calculation.description
                },
              ],
              foot: [
              ],
              preview: true,

              storage: payload,
            },
          })
        );
        break;

      case "SAVE_AS_INPUT_FILE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save As",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "inputFile",
                  placeholder: "Input File Name",
                  limit: 20,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                  inputPlaceHolder: true,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "ERROR_CALCULATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Start Calculation",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `This Calculation still have an ERROR formula. You will be directed to the Composer View`,
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  followup: true
                },
                {
                  type: "SUBMIT",
                  label: "Edit",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CREATE_TEAM_DRIVES":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create a Drive",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Drive Name",
                  limit: 100,
                  defaultValue: "",
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Create",
                },
              ],
              storage: null,
            },
          })
        );
        break;

      /**
       * COPY FROM SAVE CONFIRMATION LOGGER
       * need to separate logic between old and new logger modal
       * to prevent error on existing code
       */
      case "REFACTOR_SAVE_CONFIRMATION_LOGGER":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Save Input File",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `You are about to proceed to Print View. You can click the "Save" button to keep your inputs for next time`,
                  labelAlign: "center",
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "No",
                  followup: true,
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "RENAME_TEAM_DRIVES":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: payload.isDrive ? "Drive Name" : "Folder Name",
                  limit: payload.isDrive ? 100 : 60,
                  defaultValue: payload?.title,
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;


      case "MANAGE_MEMBERS":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: 'Manage Members',
              headAlign: 'center',
              body: [
              ],
              foot: [
              ],
              isDrive: true,
              storage: payload,
            },
          })
        );
        break;

      case "NEW_CALCULATION_TEAM_DRIVES":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Create Calculation",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "title",
                  placeholder: "Calculation Name",
                  limit: 60,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationName",
                },
                {
                  type: "TEXT",
                  name: "description",
                  placeholder: "Description",
                  limit: 140,
                  defaultValue: "",
                  isDisabled: false,
                  dataTestid: "inputCalculationDescription",
                },
                {
                  type: "TEXT",
                  name: "createdBy",
                  placeholder: "Created By",
                  limit: null,
                  defaultValue: userDetails,
                  isDisabled: true,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "CREATE",
                  dataTestid: "btnCreate",
                },
              ],
              storage: payload,
            },
          })
        );
        break;
      case "START_CALCULATION_ERROR":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Start Calculation",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `This Calculation still have an ERROR formula. Please contact the originator of the calculation`,
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "SUBMIT",
                  label: "Back to Folder",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "NOT_ALLOWED_OPEN_REPORT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Open Report",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: "You are not authorized to access this page",
                  labelAlign: "center"
                },
              ],
              foot: [
                {
                  type: "CANCEL",
                  label: "OK",
                  dataTestid: "btnCancel",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "START_CALCULATION_ERROR_CONFIRMATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Information",
              headAlign: "center",
              body: [
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Back to Compose",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "Continue to Log",
                  dataTestid: "btnCreate",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "COPY_CALCULATION_TO_BUSINESS_DRIVE":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: 'Copy To',
              headAlign: 'center',
              body: [
              ],
              foot: [
              ],
              storage: payload,
            },
          })
        );
        break;

      case "COPY_CALCULATION_TO_BUSINESS_DRIVE_CONFIRMATION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Copy to a Business Drives?",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: `All members in ${payload.destinationFolderTitle} will gain access`,
                  labelAlign: "center"
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  dataTestid: "btnCancel",
                },
                {
                  type: "SUBMIT",
                  label: "Copy to Drive",
                  dataTestid: "btnCreate",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "RENAME_CHAT_SESSION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Rename",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "conversationName",
                  placeholder: "Conversation Name",
                  limit: 30,
                  defaultValue: payload.conversation.title,
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "CANCEL",
                },
                {
                  type: "SUBMIT",
                  label: "SAVE",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "CONFIRMATION_DELETE_CHAT_SESSION":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Delete",
              headAlign: "center",
              body: [
                {
                  type: "LABEL",
                  label: 'Do you want to delete conversation?',
                  labelAlign: 'center'
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                  dataTestid: "btnCancel",
                },
                {
                  type: "DANGER",
                  label: "Delete",
                  dataTestid: "btnConfirm",
                },
              ],
              storage: payload,
            },
          })
        );
        break;

      case "ADD_COMPANY_USER_MANAGEMENT":
        dispatch(
          reducerModifierDataFormModalActionPopUp({
            isOpen: true,
            type: type,
            content: {
              head: "Add Company",
              headAlign: "center",
              body: [
                {
                  type: "TEXT",
                  name: "name",
                  placeholder: "Company Name",
                  limit: 30,
                  defaultValue: "",
                  isDisabled: false,
                },
              ],
              foot: [
                {
                  type: "CANCEL_OUTLINED",
                  label: "Cancel",
                },
                {
                  type: "SUBMIT",
                  label: "Save",
                },
              ],
              storage: payload,
            },
          })
        );
        break;


      default:
        console.error("Wrong type on formModalSetupActionPopUp");
        break;
    }
  };
}

// FORM MODAL SUBMIT

export function formModalSubmitActionPopUp(
  payload: any,
): ThunkAction<Promise<void>, Record<string, unknown>, Record<string, unknown>, AnyAction> {
  return async (dispatch: any, getState: any) => {
    const dataFormModal: any = getState().formModalPopUp.dataFormModal;

    // REDUCER STATE LIBRARY, PLEASE USE SUPER DECENT VARIABLE
    const activePage: any = getState().composer.activePage;
    const dataPages: any = Array.from(getState().composer.dataPages);
    const dataHeadOfCalculation: any =
      getState().composer.dataHeadOfCalculation;
    const dataBodyOfCalculation = Array.from(
      getState().composer.dataBodyOfCalculation
    );
    const calInfo: any = getState().calInfo;
    const workspace: any = getState().global.workspace;
    const savingTable: any = getState().logger.savingTable;
    const userData: any = getState().users.changePlan;
    const subscription = getState().login.subscription;
    const usersLimit = getState().users.userLimit;

    let backendResponse: any;
    let temp: any;
    let targetName: any;

    // SLICING dataFormModal
    const { storage } = dataFormModal.content;

    // DO THE LOGIC HERE
    // CLOSE THE MODAL USE dispatch(reducerModifierCloseEventActionPopUp(true))
    switch (dataFormModal.type) {
      case "EDIT_CALCULATION_FROM_COMPOSER":
        try {
          // BE INTEGRATION

          service = new Service("calculations");
          backendResponse = await service.putResource(
            dataHeadOfCalculation.idCalculation,
            "info",
            {
              title: payload.title,
              description: payload.description,
            }
          );

          // FE INTEGRATION
          dispatch(
            reducerModifierDataHeadOfCalculationActionComposer({
              ...dataHeadOfCalculation,
              title: payload.title,
              description: payload.description,
            })
          );

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_PAGE_FROM_COMPOSER":
        try {
          // BE INTEGRATION
          service = new Service("calculations");
          backendResponse = await service.deleteSubResource(
            dataHeadOfCalculation.idCalculation,
            "page",
            activePage
          );

          // FE INTEGRATION
          dataBodyOfCalculation.splice(activePage, 1);
          dataPages.splice(activePage, 1);
          dispatch(
            reducerModifierDataBodyOfCalculationActionComposer(
              dataBodyOfCalculation
            )
          );
          dispatch(reducerModifierDataPagesActionComposer(dataPages));
          dispatch(reducerModifierActivePageActionComposer(0));

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_SHOWTOLOGGER_PAGE_FROM_COMPOSER":
        try {
          // BE INTEGRATION
          service = new Service("calculations");
          backendResponse = await service.putSubResource(dataFormModal.content.storage.idCalculation, 'page', activePage, {
            isHidden: true
          });

          // FE INTEGRATION
          dataPages[activePage] = {
            ...dataPages[activePage],
            hidden: true,
          };
          dispatch(reducerModifierDataPagesActionComposer(dataPages));

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve(backendResponse);
        } catch (error) {
          return Promise.reject(error);
        }

      case "NEW_CALCULATION_FROM_COMPOSER":
        try {
          setPreventChangesCalculation({});
          // BE INTEGRATION
          service = new Service("calculations");
          backendResponse = await CalculationService.createCalculation({
            folderId: dataFormModal.content.storage.folderIdCalculation,
            title: payload.title,
            description: payload.description,
          });
          const loggerPreview = Boolean(dataFormModal.content.storage.loggerPreview);
          return Promise.resolve({
            ...backendResponse,
            loggerPreview
          });
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "SAVE_AS_FROM_COMPOSER":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "folder" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "BIGPATH"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.id;
            }
          }

          // BE INTEGRATION
          service = new Service("calculations");
          backendResponse = await service.post(
            {
              id: dataFormModal.content.storage.idCalculation,
              title: payload.title,
              location: temp,
            },
            "saveas"
          );

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_COMPOSER":
        try {
          service = new Service("calculations");
          backendResponse = await service.post(
            {
              id: dataFormModal.content.storage.idCalculation,
              title: dataHeadOfCalculation.title,
              location: dataFormModal.content.storage.location,
            },
            "saveas"
          );

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "SUCCESS",
              `${dataHeadOfCalculation.title} has been publish to Enterprise Calculation`
            )
          );

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "PUBLISH_TO_COMMUNITY_CALCULATION_FROM_COMPOSER":
        try {
          service = new Service("calculations");
          backendResponse = await service.post(
            {
              id: dataFormModal.content.storage.idCalculation,
              title: dataHeadOfCalculation.title,
              location: dataFormModal.content.storage.location,
            },
            "saveas"
          );

          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "SUCCESS",
              `${dataHeadOfCalculation.title} has been publish to Community Calculation`
            )
          );

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "EXIT_CALCULATION_FROM_COMPOSER":

        return Promise.resolve(dataFormModal.content.storage);

      case "CONFIRMATION_DELETE_CALCULATION":
        try {
          if (calInfo.calApproval === 0) {
            try {
              const resultCalInfoDelete: any = await dispatch(
                calInfoDelete(calInfo.calId)
              );
              if (resultCalInfoDelete === `Success Delete`) {
                dispatch(encalDataAction(workspace));
                dispatch(toastSetupActionPopUp("INFO", "File move to Archive"));
                dispatch(getUserCalculationLimit());
                dispatch(calIdAction("", "", 0, 0, "", ""));
              } else {
                throw resultCalInfoDelete;
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(
              toastSetupActionPopUp(
                "INFO",
                "Cannot Delete because on process Approval for Principal"
              )
            );
          }
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_MULTIPLE_CALCULATION":
        try {
          const ids = storage;
          await dispatch(calInfoMultipleDelete({ ids }));
          dispatch(encalDataAction(workspace));
          dispatch(toastSetupActionPopUp("INFO", "This file has been deleted"));
          dispatch(getUserCalculationLimit());
          dispatch(calIdAction("", "", 0, 0, "", ""));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_CALCULATION_FROM_MYCALCULATION":
        try {
          await dispatch(calInfoRename(calInfo, payload));
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "HANDLE_CHANGE_PLANS":
        try {
          const { subscriptionUser } = storage;
          await dispatch(handleChangeUserPlan({ payload, subscriptionUser }));
          dispatch(setChangePlan(!userData));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CHANGE_BILLING_PAYMENT_STATUS":
        try {
          const value = dataFormModal.content.body[1].value;
          const paymentStatus = value === 'Paid' ? 'PAID' : value === 'Failed' ? 'FAILED' : 'WAITING_FOR_PAYMENT';
          const data = storage.map((item: any) => {
            return {
              id: item.id,
              status: paymentStatus
            };
          });
          await dispatch(changeBillingPaymentStatus(data));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return (
            dispatch(toastSetupActionPopUp(
              "ERROR_SECONDARY",
              error.response.data.message
            ))
          );
        }

      case "ADD_BILLING_MEMBER":
        try {
          const service = new Service("user");
          const PAID = 'PAID';
          const plan = payload?.plan;
          const selectedPlan = plan === "Enterprise"
            ? 'ENTERPRISE'
            : plan === "Pro"
              ? 'PRO'
              : plan === "Pro Plus"
                ? 'PRO_PLUS'
                : plan === "Business"
                  ? 'BUSINESS'
                  : plan === "Business Plus"
                    ? 'BUSINESS_PLUS'
                    : 'BASIC';
          const data = {
            email: payload.email,
            parentId: storage.id,
            plan: selectedPlan,
            amount: parseInt(payload.invoice)
          };

          const response = await service.getBillingMemberEmail(payload.email);
          if (response.data.latestInvoice.status === PAID) {
            dispatch(
              formModalSetupActionPopUp('CONFIRM_ADD_BILLING_MEMBER', { data })
            );
            return Promise.resolve();
          } else {
            await dispatch(addBillingMember(data));
            dispatch(reducerModifierCloseEventActionPopUp(true));
            return Promise.resolve();
          }
        } catch (error: any) {
          switch (error.response.status) {
            case 400:
              return (
                dispatch(toastSetupActionPopUp(
                  "ERROR_SECONDARY",
                  "Invalid Email Format"
                )
                )
              );
            case 404:
              return (
                dispatch(
                  toastSetupActionPopUp(
                    "ERROR_SECONDARY",
                    "Email Does Not Exist"
                  )
                )
              );
            default:
              return (
                dispatch(toastSetupActionPopUp(
                  "ERROR_SECONDARY",
                  error.response.data.message
                ))
              );
          }
        }

      case "CONFIRM_ADD_BILLING_MEMBER":
        try {
          await dispatch(addBillingMember(storage.data));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "MAKE_COPY_CALCULATION_FROM_DRIVE":
        try {
          await dispatch(
            makeCopyCal({
              id: storage.item.id,
              title: payload.title,
              location: storage.item.folderId,
            })
          );
          dispatch(fetchTeamDrives(storage.item.folderId));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              "Duplicate with name " + payload.title
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "MAKE_COPY_CALCULATION_FROM_MYCALCULATION":
        try {
          await dispatch(
            makeCopyCal({
              id: calInfo.calId,
              title: payload.title,
              location: workspace,
            })
          );


          dispatch(encalDataAction(workspace));
          dispatch(getUserCalculationLimit());
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              "Duplicate with name " + payload.title
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          return (
            dispatch(toastSetupActionPopUp(
              "ERROR_SECONDARY",
              error.response.data.message
            ))
          );
        }

      case "PUBLISH_MULTIPLE_CALCULATIONS":
        try {
          const { personalWorkspaceId, selectedCommId, text } = dataFormModal.content.storage;
          if (checkSubscriptionMultipleSelect(subscription, usersLimit, selectedCommId.length, dispatch)) {
            await dispatch(makeMultipleCopy({
              "destinationFolderId": text === "Community" ? personalWorkspaceId.communityWorkspaceId : personalWorkspaceId.personalWorkspaceId,
              "calculationIds": selectedCommId
            }));

            dispatch(encalDataAction(workspace));
            dispatch(getUserCalculationLimit());
            dispatch(reducerModifierCloseEventActionPopUp(true));
            text === "Community" ? dispatch(
              toastSetupActionPopUp(
                "INFO",
                "Files has been published to Community Calculations"
              )
            ) : dispatch(
              toastSetupActionPopUp(
                "INFO",
                "Files has been added to My Calculations"
              )
            );
          }

          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "EDIT_CALCULATION_INFO_FROM_MYCALCULATION":
        try {
          await dispatch(editCalcInfo(calInfo.calId, payload));
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "EDIT_CALCULATION_INFO_FROM_DRIVE":
        try {
          await dispatch(editCalcInfo(calInfo.calId, payload));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(fetchTeamDrives(storage.folderId));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "UPGRADE_PLAN_SUBSCRIPTION":
        try {
          await dispatch(updateGradeAccountAction({ plan: "PRO" }));
          dispatch(getUserCalculationLimit());
          dispatch(formModalSetupActionPopUp
            ('UPGRADE_SUCCESS_SUBSCRIPTION', { image: images.subscriptionSuccess }));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }


      case "EXIT_LOGGER_TO_INPUT_FILE":
        try {
          const {
            history,
            calId,
            latex,
            inputFile,
            loggerFormValues,
            loggerId,
          } = dataFormModal.content.storage;

          if (latex) {
            const latexData: any = await convertLatex(latex);
            const savingData =
              latexData.length > 0
                ? await insertLatex(
                  latexData,
                  loggerFormValues.length > 0
                    ? loggerFormValues
                    : inputFile.pages
                )
                : loggerFormValues.length > 0
                  ? loggerFormValues
                  : inputFile.pages;
            const setLogger = {
              pages: addTableData(savingData, savingTable),
            };
            await dispatch(savingInputFileToApi(inputFile.id, inputFile.id === 'temp' ? {
              ...setLogger,
              id: null,
              calculationId: calId,
              title: inputFile.title
            } : setLogger));
          } else {
            const savingData =
              loggerFormValues.length > 0 ? loggerFormValues : inputFile.pages;
            const setLogger = {
              pages: addTableData(savingData, savingTable),
            };
            await dispatch(savingInputFileToApi(inputFile.id, inputFile.id === 'temp' ? {
              ...setLogger,
              id: null,
              calculationId: calId,
              title: inputFile.title
            } : setLogger));
          }
          dispatch(reducerModifierCloseEventActionPopUp(true));
          history.push(`/input-file/${calId ? calId : loggerId}`);
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      /**
       * REFACTOR
       */
      case "REFACTOR_EXIT_TO_INPUT_FILE_MANAGER":
        try {
          const { history, calId, loggerId, isDrive } = dataFormModal.content.storage;
          await dispatch(saveInputFile());
          dispatch(reducerModifierCloseEventActionPopUp(true));
          history.push({
            pathname: `/input-file/${calId ? calId : loggerId}`,
            state: {
              ...(isDrive && { calculationType: 'BUSINESS' })
            }
          });
        } catch (error) {
          return Promise.reject(error);
        }
        break;

      case "RENAME_FOLDER":
        try {
          const isDrive = Boolean(dataFormModal.content.storage?.type === 'TEAM');
          await dispatch(
            renameFolder({
              id: calInfo.calId,
              title: payload.title,
            })
          );
          isDrive ? dispatch(fetchTeamDrives(dataFormModal.content.storage.folderId)) : dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_FOLDER":
        const servicefolder = new Service("folders");
        const isDrive = Boolean(dataFormModal.content.storage?.type === 'TEAM');
        let count;
        try {
          if (dataFormModal.content.storage === 'community') {
            count = await servicefolder.getResource(`${calInfo.calId}/view?type=FOLDER,CALCULATION&isActive=true`);
          }
          if (count === undefined || count.data.items.length === 0) {
            await dispatch(deleteFolder(isDrive ? dataFormModal.content.storage.id : calInfo.calId));
            isDrive ? dispatch(fetchTeamDrives(dataFormModal.content.storage.folderId)) : dispatch(encalDataAction(workspace));
            dispatch(calIdAction("", "", 0, 0, "", ""));
          } else {
            dispatch(
              toastSetupActionPopUp(
                "ERROR",
                "Cannot Delete Folder : The directory is not empty"
              )
            );
          }
          dispatch(getUserCalculationLimit());
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "ADD_FOLDER":
        if (payload.title.length <= 2) {
          return Promise.reject("Title length can't be less than 3 chars and more than 60 chars");
        };
        try {
          if (dataFormModal.content.storage === 'community') {
            await dispatch(
              addFolder({
                parentId: workspace,
                title: payload.title,
                type: "COMMUNITY",
              })
            );
          } else if (dataFormModal.content.storage.type === 'TEAM') {
            const { id } = dataFormModal.content.storage;
            await dispatch(
              addFolder({
                parentId: id,
                title: payload.title,
                type: "TEAM",
              })
            );
          } else {
            await dispatch(
              addFolder({
                parentId: workspace,
                title: payload.title,
                type: "PERSONAL",
              })
            );
          }

          dataFormModal.content.storage.type === 'TEAM' ? dispatch(fetchTeamDrives(dataFormModal.content.storage.id)) : dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CREATE_CALCULATION_FROM_MYCALCULATION":
        try {
          setPreventChangesCalculation({});
          await dispatch(createNewCalc(workspace, payload, "history"));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_INPUT_FILE":
        try {
          await dispatch(renameInputFile(storage.inputFileId, payload));
          dispatch(refreshInputFile(storage.inputFileId));
          dispatch(fetchCalculationInputFileList(storage.calculationId));
          dispatch(submitLoading(false));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "MOVE_CALCULATION":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "folder" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "BIGPATH"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.id;
              targetName =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.title;
            }
          }

          // BE INTEGRATION
          await dispatch(
            moveCal(dataFormModal.content.storage.idCalculation, temp)
          );
          dispatch(encalDataAction(workspace));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              calInfo.calName + " has been moving to " + targetName
            )
          );
          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "MOVE_MULTIPLE_CALCULATION":
        try {
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "folder" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "BIGPATH"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.id;
              targetName =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.title;
            }
          }
          // BE INTEGRATION
          await dispatch(
            moveMultipleCal(temp, dataFormModal.content.storage.activeIds)
          );
          dispatch(encalDataAction(workspace));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              "Files has been moving to " + targetName
            )
          );
          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "MOVE_FOLDER":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "folder" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "BIGPATH"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.id;
              targetName =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.title;
            }
          }

          // BE INTEGRATION
          await dispatch(
            moveFolder({
              folderId: dataFormModal.content.storage.folderId,
              destinationFolderId: temp,
            })
          );
          dispatch(encalDataAction(workspace));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              dataFormModal.content.storage.folderName +
              " has been moving to " +
              targetName
            )
          );
          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_PRINT_VIEW_TO_REPORT":
        try {
          const report = dataFormModal.content.storage;
          const { isDriveReportManager } = report;
          const result = await dispatch(
            saveReport(
              report.currentReport.id,
              {
                title: report.title,
                notes: "",
                isActive: true,
                format: report.format,
              },
              report.pdfFile
            )
          );

          dispatch(reducerModifierCloseEventActionPopUp(true));
          if (result.message === "Success") {
            report.history.push({
              pathname: `/report/${result.data.calculationId}`,
              state: {
                ...(isDriveReportManager && { calculationType: 'BUSINESS' })
              }
            });
          }
          return Promise.resolve(result);
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "EDIT_EMAIL_ACCOUNT_SETTING":
        try {
          const serviceUser = new Service("user");
          const data = await serviceUser.putAction("email", {
            email: payload.email,
            password: payload.password
          });
          const userDetail = getUserDetails();
          setUserDetails({ ...userDetail, email: payload.email });
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve(data);
        } catch (error: any) {
          return Promise.reject(error);
        }
      case "CONFIRMATION_DELETE_FILE":
        try {
          await dispatch(deleteFile(calInfo.calId));
          dispatch(encalDataAction(workspace));
          dispatch(calIdAction("", "", 0, 0, "", ""));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_FILE":
        try {
          await dispatch(renameFile(calInfo.calId, payload.title));
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "MOVE_FILE":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "folder" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "BIGPATH"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.id;
              targetName =
                getState().formModalPopUp.dataFormModal.content.body[i]
                  .currentValue.title;
            }
          }

          // BE INTEGRATION
          await dispatch(moveFile(dataFormModal.content.storage.fileId, temp));
          dispatch(encalDataAction(workspace));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              dataFormModal.content.storage.fileName +
              " has been moving to " +
              targetName
            )
          );
          // CLOSING MODAL
          dispatch(reducerModifierCloseEventActionPopUp(true));

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }

      case "RENAME_REPORT":
        try {
          dispatch(actionReport("", {}));
          await dispatch(
            renameReport(dataFormModal.content.storage.reportId, payload)
          );
          dispatch(
            fetchCalculationReportList(dataFormModal.content.storage.calculationId)
          );
          dispatch(actionReport("RENAME_REPORT_SUCCESS", payload));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_REPORT":
        try {
          dispatch(actionReport("", {}));
          await dispatch(deleteReport(dataFormModal.content.storage.reportId));
          dispatch(
            fetchCalculationReportList(dataFormModal.content.storage.calculationId)
          );
          dispatch(actionReport("DELETE_REPORT_SUCCESS", {}));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(toastSetupActionPopUp("INFO", "Report move to Archive"));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "PUBLISH_TO_ENTERPRISE_CALCULATION_FROM_MYCALCULATION":
        try {
          await dispatch(
            makeCopyCal({
              id: calInfo.calId,
              title: calInfo.calName,
              location: dataFormModal.content.storage.location,
            })
          );
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "SUCCESS",
              `${calInfo.calName} has been publish to Enterprise Calculation`
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "PUBLISH_TO_COMMUNITY_CALCULATION_FROM_MYCALCULATION":
        try {
          await dispatch(
            makeCopyCal({
              id: calInfo.calId,
              title: calInfo.calName,
              location: dataFormModal.content.storage.location,
              description: calInfo.calDescription
            })
          );
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp(
              "SUCCESS",
              `${calInfo.calName} has been publish to Community Calculation`
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "COPY_TO_MYCALC_FROM_ENTERPRISE":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "version" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "SELECT"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i].value;
            }
          }
          if (temp) {
            const versionData: any =
              dataFormModal.content.storage.data.dataCalculation.version;
            const version: any = versionData.find(
              (o: any) => o.versionName === temp
            );
            await dispatch(
              makeCopyCal({
                id: version.id,
                title: calInfo.calName,
                location: dataFormModal.content.storage.personalWorkspaceId,
              })
            );
            dispatch(encalDataAction(dataFormModal.content.storage.id));
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(
              toastSetupActionPopUp(
                "SUCCESS",
                `${dataFormModal.content.storage.calName} has been added to
              My Calculation`
              )
            );
          } else {
            return Promise.reject("The version can not be empty");
          }
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "SAVE_CONFIRMATION_LOGGER":
        try {
          const payload = dataFormModal.content.storage;
          if (payload.latex) {
            const latexData: any = await convertLatex(payload.latex);
            const savingData =
              latexData.length > 0
                ? await insertLatex(
                  latexData,
                  payload.loggerFormValues.length > 0
                    ? payload.loggerFormValues
                    : payload.inputFile.pages
                )
                : payload.loggerFormValues.length > 0
                  ? payload.loggerFormValues
                  : payload.inputFile.pages;
            const setLogger = {
              pages: addTableData(savingData, savingTable),
            };
            await dispatch(
              savingInputFileToApi(payload.inputFile.id, setLogger)
            );
          } else {
            const savingData =
              payload.loggerFormValues.length > 0
                ? payload.loggerFormValues
                : payload.inputFile.pages;
            const setLogger = {
              pages: addTableData(savingData, savingTable),
            };
            await dispatch(
              savingInputFileToApi(payload.inputFile.id, setLogger)
            );
          }
          dispatch(reducerModifierCloseEventActionPopUp(true));
          backendResponse = dataFormModal.content.storage.inputFile;
          return Promise.resolve(backendResponse);
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_COMMUNITY":
        try {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          await dispatch(
            calculationDelete(dataFormModal.content.storage.payload)
          );
          dispatch(encalDataAction(workspace));
          dispatch(toastSetupActionPopUp("INFO", `File is moved to Archive`));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "COPY_TO_MYCALC_FROM_COMMUNITY":
        try {
          await dispatch(
            makeCopyCal({
              id: calInfo.calId,
              title: calInfo.calName,
              location: dataFormModal.content.storage.location,
            })
          );
          dispatch(encalDataAction(workspace));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(getUserCalculationLimit());
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              `${calInfo.calName} has been added to My Calculation`
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "ADD_USER_USER_MANAGEMENT":
        try {
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "company" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "SELECT"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i].value;
            }
          }
          if (temp) {
            const companyId = dataFormModal.content.storage.companies.find((item: CompanyInterface) => item.title === temp).id;
            payload.role = ['COMPOSER'];
            payload['personalUserCompany'] = payload['Organization/Institution'];
            delete payload['Organization/Institution'];
            delete payload['company'];
            await dispatch(postAddUser({ ...payload, companyId }));

            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(toastSetupActionPopUp("INFO", `New person has been added`));
            await dispatch(
              fetchAllUsers(
                getState()?.users?.dataUsers?.page ?? 0,
                50,
                "",
                "ADD"
              )
            );

            return Promise.resolve();
          } else {
            console.log('errr :');
            return Promise.reject("The company can not be empty");
          }
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "DEACTIVATE_ACCOUNT_MANAGER":
        try {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          const id = await getState().formModalPopUp.dataFormModal.content
            .storage;
          const data = await dispatch(deactivateUser(id));
          await dispatch(isActiveUser(data.isActive));
          await dispatch(
            fetchAllUsers(
              getState().users.dataUsers.page,
              getState().users.dataUsers.page_size,
              "",
              "EDIT"
            )
          );

          return Promise.resolve(data.data.isActive);
        } catch (error) {
          return Promise.reject(error);
        }

      case "ACTIVATE_USER_ACCOUNT_MANAGER":
        try {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          const id = await getState().formModalPopUp.dataFormModal.content
            .storage;
          const data = await dispatch(activateUser(id));
          await dispatch(isActiveUser(data.data.isActive));
          await dispatch(
            fetchAllUsers(
              getState().users.dataUsers.page,
              getState().users.dataUsers.page_size,
              "",
              "EDIT"
            )
          );

          return Promise.resolve(data);
        } catch (error) {
          return Promise.reject(error);
        }

      case "EDIT_ACCOUNT_MANAGER":
        try {
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "company" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "SELECT"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i].value;
            }
          }
          const companyId = dataFormModal.content.storage.res.data.find((item: CompanyInterface) => item.title === temp).id;
          const userList = dataFormModal.content.storage.data;
          payload.id = getState().users.activeItem.id;
          payload['personalUserCompany'] = payload['Organization/Institution'];
          delete payload['Organization/Institution'];
          const response = await dispatch(editUser({ ...payload, companyId }));

          const newUserList = {
            ...userList,
            data: userList?.data?.map((user: any) => {
              const isEdited = Boolean(response.data.id === user.id);
              if (isEdited) {
                return {
                  ...user,
                  name: response.data.name,
                  personalUserCompany: response.data.personalUserCompany,
                  companyId
                };
              } else
                return user;
            })
          };
          await dispatch(updateUserList(newUserList));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(
            toastSetupActionPopUp("INFO", `User has been successfully edited`)
          );
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(error);
        }

      case "RESET_PASSWORD_ACCOUNT_MANAGER":
        try {
          dispatch(resetPassword(dataFormModal.content.storage.id));
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              `New Password has been sent to ` +
              dataFormModal.content.storage.name +
              ` email`
            )
          );
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "FORGOT_PASSWORD":
        try {
          const serviceRecover = new Service("/user/forgot-password");
          await serviceRecover.put('', { email: payload.email });
          dispatch(
            toastSetupActionPopUp(
              "INFO",
              "New password has been sent to your email"
            )
          );
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "SAVE_REPORT_PRINT_VIEW":
        try {
          const report = dataFormModal.content.storage;
          const result = await dispatch(
            saveReport(
              report.currentReport.id,
              {
                title: report.title,
                notes: "",
                isActive: true,
                format: report.format,
              },
            )
          );
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve(result);
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "SAVE_REPORT_PRINT_VIEW_BREADCRUMB":
        try {
          const { isDriveCalculation } = dataFormModal.content.storage;
          const report = dataFormModal.content.storage;
          const result = await dispatch(
            saveReport(
              report.currentReport.id,
              {
                title: report.title,
                notes: "",
                isActive: true,
                format: report.format,
              },
            )
          );
          sessionStorage.clear();
          dispatch(reducerModifierCloseEventActionPopUp(true));
          if (result.message === "Success") {
            report.history.push({
              pathname: `/${report.type}/${report.calculationId}/${report.inputFileId}`,
              state: {
                ...(isDriveCalculation && { calculationType: 'BUSINESS' })
              }
            });
          }
          return Promise.resolve(result);
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }
        break;
      case "LEAVE_REPORT_PRINT_VIEW":
        try {
          const { history, calculationId, inputFileId } =
            dataFormModal.content.storage;
          history.push(`/logger/${calculationId}/${inputFileId}`);
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "OPEN_OTHER_REPORT":
        try {
          const { data, history, currentReport } =
            dataFormModal.content.storage;
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "report" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "SELECT"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i].value;
            }
          }
          const dataReport = data.find((el: any) => {
            return el.title === temp;
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          history.push(
            `/print-view/${currentReport.calculationId}/${currentReport.inputFileId}/${dataReport.id}`
          );
          setTimeout(() => {
            window.location.reload();
          }, 100);
          return Promise.resolve();
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "START_CALCULATION":
        try {
          // LOOPING FOR FUN
          for (
            let i = 0;
            i < getState().formModalPopUp.dataFormModal.content.body.length;
            i++
          ) {
            if (
              getState().formModalPopUp.dataFormModal.content.body[i].name ===
              "inputFile" &&
              getState().formModalPopUp.dataFormModal.content.body[i].type ===
              "SELECT"
            ) {
              temp =
                getState().formModalPopUp.dataFormModal.content.body[i].value;
            }
          }
          if (temp) {
            const InputFileList: any =
              dataFormModal.content.storage.inputFileList;

            const inputFileId: any = InputFileList.find(
              (o: any) => o.title === temp
            );

            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(submitLoading(false));

            if (temp !== "Create New") {
              window.location.href = `/logger/${dataFormModal.content.storage.calculationId}/${inputFileId.id}`;
            }

            return Promise.resolve(backendResponse);
          } else {
            return Promise.reject("The input file can not be empty");
          }
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "COMPOSER_DATA_CHANGES_CONFIRMATION":
        try {
          dispatch(
            formModalSetupActionPopUp(
              "COMPOSER_DATA_CHANGES_NEW_CALCULATION",
              dataFormModal.content.storage
            )
          );
          return Promise.resolve();
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "COMPOSER_DATA_CHANGES_NEW_CALCULATION":
        try {
          if (checkSubscriptionAndDate(subscription, usersLimit, dispatch)) {
            dispatch(
              formModalSetupActionPopUp(
                "COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION",
                dataFormModal.content.storage
              )
            );
          }
          return Promise.resolve();
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "EDIT_PASSWORD_ACCOUNT_SETTING":
        try {
          const serviceUser = new Service("user");
          await serviceUser.putAction("password", {
            currentPassword: payload.currentPassword,
            newPassword: payload.newPassword,
            confirmedPassword: payload.confirmNewPassword,
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }
      case "COMPOSER_DATA_CHANGES_INPUT_DATA_CALCULATION":
        try {
          const result = await dispatch(
            makeCopyCal({
              id: dataFormModal.content.storage.calculationInfo.calculationId,
              title: payload.title,
              description: payload.description,
              location: dataFormModal.content.storage.calculationInfo.folderId,
            })
          );
          const actionState: any = dataFormModal.content.storage.action;
          const typeHandler: string =
            dataFormModal.content.storage.action.typeHandler;
          const newCalcId: string = result.data.id;
          setPreventChangesCalculation({});
          dispatch(setPreventChanges(false));
          dispatch(
            setCalculationTarget({
              calculationId: result.data.id,
              folderId: result.data.folderId,
            })
          );
          typeHandler === "DND"
            ? dispatch(
              setActionState({
                ...actionState,
                idCalculation: newCalcId,
              })
            )
            : dispatch(setActionState(actionState));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve(result);
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "CREATE_INPUT_FILE_FROM_COMPOSER_NAVBAR":
        return new Promise(async (resolve: any, reject: any) => {
          try {
            const title: string = payload.inputFile;
            const { calculationId } = dataFormModal.content.storage;
            if (title) {
              const result = await InputFileService.create({
                calculationId: calculationId,
                title: title,
                description: "",
              });
              dispatch(reducerModifierCloseEventActionPopUp(true));
              window.location.href = `/logger/${result.calculationId}/${result.id}`;

              resolve();
            } else {
              reject("The input file can not be empty");
            }
          } catch (error: any) {
            reject(error);
          }
        });

      case "SAVE_CONFIRMATION_LOGGER_CHANGES":
        try {
          const {
            id,
            calculationId,
            history,
            folderId,
            title,
            pages
          } = dataFormModal.content.storage;
          const newFields = await validateDecimalChanges(pages);

          await InputFileService.saveInputFile({
            id: id,
            calculationId: calculationId,
            title: title,
            pages: newFields
          });

          dispatch(reducerModifierCloseEventActionPopUp(true));
          if (folderId === getPersonalWorkspaceId()) {
            history.push({
              pathname: `/calculations/my-calculation`,
              state: {
                from: 'logger',
                calculationId: calculationId
              }
            });
          } else {
            history.push({
              pathname: `/calculations/my-calculation/${folderId}`,
              state: {
                from: 'logger',
                calculationId: calculationId
              }
            });
          }

          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "SAVE_REPORT_PRINT_VIEW_CONFIRMATION_TO_MYCALCULATION":
        try {
          const report = dataFormModal.content.storage;
          const result = await dispatch(
            saveReport(
              report.id,
              {
                title: report.title,
                notes: "",
                isActive: true,
                format: report.format,
              },
            )
          );
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve({
            ...result,
            calculationId: report.calculationId,
            folderId: report.folderId,
          });
        } catch (error: any) {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.reject(error);
        }

      case "CONFIRMATION_DELETE_INPUT_FILE":
        try {
          await dispatch(
            inputFileInfoDelete(storage.inputFileId)
          );
          dispatch(setActionReport("DELETE_INPUT_FILE_SUCCESS", {}));
          dispatch(fetchCalculationInputFileList(storage.calculationId));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(submitLoading(false));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "PREVIEW_CALCULATION":
        const { calculation, userLimit } = dataFormModal.content.storage;
        try {
          if (checkSubscriptionAndDate(subscription, userLimit, dispatch)) {
            await dispatch(
              makeCopyCal({
                id: calculation.id,
                title: calculation.title,
                description: calculation.description,
                location: calculation.location,
              })
            );
            dispatch(encalDataAction(workspace));
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(
              toastSetupActionPopUp(
                "INFO",
                `${calculation.title} has been added to My Calculation`
              )
            );
          }
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "SAVE_AS_INPUT_FILE":
        try {
          const saveData = dataFormModal.content.storage;
          if (!payload.inputFile) {
            return Promise.reject("The input file name can not be empty");
          }
          if (saveData.inputFile.id === 'temp') {
            if (saveData.latex) {
              const latexData: any = await convertLatex(saveData.latex);
              const savingData =
                latexData.length > 0
                  ? await insertLatex(
                    latexData,
                    saveData.loggerFormValues.length > 0
                      ? saveData.loggerFormValues
                      : saveData.inputFile.pages
                  )
                  : saveData.loggerFormValues.length > 0
                    ? saveData.loggerFormValues
                    : saveData.inputFile.pages;
              const setLogger = {
                pages: addTableData(savingData, savingTable),
              };
              await dispatch(savingInputFileToApi(saveData.inputFile.id, saveData.inputFile.id === 'temp' ? {
                ...setLogger,
                id: null,
                calculationId: saveData.inputFile.calculationId,
                title: payload.inputFile
              } : setLogger));
            } else {
              const savingData =
                saveData.loggerFormValues.length > 0
                  ? saveData.loggerFormValues
                  : saveData.inputFile.pages;
              const setLogger = {
                pages: addTableData(savingData, savingTable),
              };
              await dispatch(savingInputFileToApi(saveData.inputFile.id, saveData.inputFile.id === 'temp' ? {
                ...setLogger,
                id: null,
                calculationId: saveData.inputFile.calculationId,
                title: payload.inputFile
              } : setLogger));
            }
          } else {
            if (saveData.latex) {
              const latexData: any = await convertLatex(saveData.latex);
              const savingData =
                latexData.length > 0
                  ? await insertLatex(
                    latexData,
                    saveData.loggerFormValues.length > 0
                      ? saveData.loggerFormValues
                      : saveData.inputFile.pages
                  )
                  : saveData.loggerFormValues.length > 0
                    ? saveData.loggerFormValues
                    : saveData.inputFile.pages;
              const setLogger = {
                pages: addTableData(savingData, savingTable),
              };
              dispatch(SaveAsInputFile({
                id: saveData.inputFile.id,
                title: payload.inputFile,
                pages: setLogger.pages
              }));
            } else {
              const savingData =
                saveData.loggerFormValues.length > 0
                  ? saveData.loggerFormValues
                  : saveData.inputFile.pages;
              const setLogger = {
                pages: addTableData(savingData, savingTable),
              };
              dispatch(SaveAsInputFile({
                id: saveData.inputFile.id,
                title: payload.inputFile,
                pages: setLogger.pages
              }));
            }
          }
          dispatch(reducerModifierCloseEventActionPopUp(true));
          backendResponse = dataFormModal.content.storage.inputFile;
          return Promise.resolve(backendResponse);
        } catch (error: any) {
          return Promise.reject(error);
        }

      /**
       * REFACTOR FROM SAVE AS INPUT FILE
       */
      case "REFACTOR_SAVE_AS_INPUT_FILE":
        try {
          const { inputFileId } = dataFormModal.content.storage;
          if (!payload.inputFile) {
            return Promise.reject("The input file name can not be empty");
          }
          /**
           * manipulation save as behavior for temp input file
           * we called save input file API instead of called save as, 
           * to prevent error input file ID is required
           */
          const response = inputFileId ? await dispatch(saveAsInputFile(payload.inputFile)) : await dispatch(saveInputFile(payload.inputFile));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          window.location.href = `/logger/${response.calculationId}/${response.id}`;
        } catch (error: any) {
          return Promise.reject(error);
        }
        break;

      case "ERROR_CALCULATION":
        const calculationId: any = dataFormModal.content.storage.calculationId;
        const history: any = dataFormModal.content.storage.history;
        const folderId: any = dataFormModal.content.storage.folderId;
        const isDriveCalculation: boolean = dataFormModal.content.storage.isDriveCalculation;
        try {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          history.push({
            pathname: `/composer/${calculationId}/${folderId}`,
            state: {
              ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
              // added drive id to router state
              ...(dataFormModal.content.storage.driveId && {
                driveId: dataFormModal.content.storage.driveId
              })
            }
          });
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "LOAD_INPUT_FILE":
        if (!payload.inputFile) {
          return Promise.reject("Input File is required");
        }
        dispatch(reducerModifierCloseEventActionPopUp(true));
        window.location.href = `/logger/${storage.calculationId}/${storage.selectedInputFile}`;
        break;

      case "CREATE_TEAM_DRIVES":
        try {
          if (payload.title) {
            await dispatch(createTeamDrive(payload.title));
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(fetchTeamDrives());
            return Promise.resolve();
          } else {
            return Promise.reject('The drive name can not be empty');
          }
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_TEAM_DRIVES":
        const data: RenameFolderPayloadI = dataFormModal.content.storage;
        try {
          if (payload.title.length <= 2) {
            return Promise.reject(`Title length can't be less than 3 chars and more than ${data.isDrive ? "100" : "60"} chars`);
          };
          if (payload.title) {
            const result: any = await dispatch(renameTeamDrive({
              id: data.id,
              title: payload.title
            }, data.isDrive));
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(fetchTeamDrives(data.isDrive ? "" : data.folderId));
            if (result.status !== 200) dispatch(
              toastSetupActionPopUp(
                "ERROR_SECONDARY",
                result.response.data.message
              )
            );
            return Promise.resolve();
          } else {
            return Promise.reject('The drive name can not be empty');
          }
        } catch (error: any) {
          return Promise.reject(error);
        };

      /**
       * COPY FROM SAVE CONFIRMATION LOGGER
       * need to separate logic between old and new logger modal
       * to prevent error on existing code
       */
      case "REFACTOR_SAVE_CONFIRMATION_LOGGER":
        try {
          const { inputFile } = getState().loggerReducer;
          const { fields } = inputFile;
          const newFields = await validateDecimalChanges(fields);
          const payload = dataFormModal.content.storage;
          await InputFileService.saveInputFile({
            id: payload.inputFile.id,
            calculationId: payload.inputFile.calculationId,
            title: payload.inputFile.title,
            pages: newFields,
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          backendResponse = dataFormModal.content.storage.inputFile;
          return Promise.resolve(backendResponse);
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "EDIT_CALCULATION_BUSINESS_ON_EDIT":
        try {
          const { id, history } = dataFormModal.content.storage;
          dispatch(formModalSetupActionPopUp('NEW_CALCULATION_TEAM_DRIVES', {
            folderId: id,
            history
          }));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_CALCULATION_FROM_DRIVE":
        try {
          const data = dataFormModal.content.storage;
          await CalculationService.renameCalculation({
            title: payload.title,
            id: data.data.id
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(toastSetupActionPopUp("INFO", "Calculation name sucessfully changed"));
          dispatch(fetchTeamDrives(data.data.folderId));
          return Promise.resolve();

        } catch (error: any) {
          return Promise.reject(error);
        };

      case "NEW_CALCULATION_TEAM_DRIVES":
        try {
          setPreventChangesCalculation({});
          const { folderId, history, driveId } = dataFormModal.content.storage;
          // BE INTEGRATION
          const response: CalculationI = await CalculationService.createCalculation({
            folderId: folderId,
            title: payload.title,
            description: payload.description,
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          history.push({
            pathname: `/composer/${response.id}/${folderId}`,
            state: {
              calculationType: 'BUSINESS',
              ...(driveId && { driveId })
            }
          });
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "START_CALCULATION_ERROR":
        try {
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "START_CALCULATION_ERROR_CONFIRMATION":
        try {
          const {
            calculationId,
            history,
            isDriveCalculation,
            loggerPreview
          } = dataFormModal.content.storage;
          Promise.all([
            dispatch(clearRecentInputFile()),
            dispatch(uploadLoggerPreview(calculationId, loggerPreview))
          ])
            .then(() => {
              dispatch(changePositionActionCircleatorBreadcrumb('logger'));
              history.push({
                pathname: `/logger/${calculationId}`,
                state: {
                  ...(isDriveCalculation && { calculationType: 'BUSINESS' }),
                }
              });
            })
            .catch(error => console.error(error));
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "COPY_CALCULATION_TO_BUSINESS_DRIVE_CONFIRMATION":
        try {
          const { calculationIds, destinationFolderId } = dataFormModal.content.storage;
          // BE INTEGRATION
          await CalculationService.copyCalculation({
            calculationIds, destinationFolderId
          });
          dispatch(reducerModifierCloseEventActionPopUp(true));
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        }

      case "RENAME_CHAT_SESSION":
        try {
          const service = new AceServices();
          const data = dataFormModal.content.storage;
          await service.renameChatSession(data.conversation.id, payload.conversationName);
          dispatch(fetchChatSessions(data.data.map((item: ChatSessionI) => {
            return {
              ...item,
              title: data.conversation.id === item.id ? payload.conversationName : item.title
            }
          })))
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(toastSetupActionPopUp("INFO", "Conversation name sucessfully changed"));
          return Promise.resolve();

        } catch (error: any) {
          return Promise.reject(error);
        };

      case "CONFIRMATION_DELETE_CHAT_SESSION":
        try {
          const service = new AceServices();
          const data = dataFormModal.content.storage;
          const activeSessionId = getSessionId();
          const sessionId = data.conversation.id;

          await service.deleteChatSession(sessionId);
          if (activeSessionId === sessionId) {
            dispatch(setChatSessionsPage({
              page: 0,
              totalPage: 0
            }))
            dispatch(setFetching(true));
            dispatch(successAction([]));
            dispatch(generateChatSessionId(window.location.pathname.split('/')[2].toUpperCase()))
          }
          dispatch(reducerModifierCloseEventActionPopUp(true));
          dispatch(setFetching(true));
          dispatch(fetchChatSessions(data.data.filter((item: ChatSessionI) => item.id !== data.conversation.id)))
          setTimeout(() => {
            dispatch(setFetching(false));
            dispatch(toastSetupActionPopUp("INFO", "Conversation sucessfully deleted"));
          }, 2000);
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error);
        };

      case "ADD_COMPANY_USER_MANAGEMENT":
        try {
          const companies = dataFormModal.content.storage.companies;
          if (companies.find((item: CompanyInterface) => item.title === payload.name)) {
            return Promise.reject("Company name already existing");
          } else {
            await UserService.postAddCompany({
              title: payload.name
            })
            dispatch(reducerModifierCloseEventActionPopUp(true));
            dispatch(toastSetupActionPopUp("INFO", `${payload.name} company successfully added`));
          }
          return Promise.resolve();
        } catch (error: any) {
          return Promise.reject(error.response.data.message);
        };

      default:
        console.error("404 - TYPE ACTION ON REDUX POP UP ACTION NOT FOUND");
        break;
    }
  };
}

// FORM MODAL MISC

export function selectFormModalActionPopUp(payload: any) {
  return async (dispatch: any, getState: any) => {
    const dataFormModal: any = getState().formModalPopUp.dataFormModal;
    for (let i = 0; i < dataFormModal.content.body.length; i++) {
      if (
        dataFormModal.content.body[i].name === payload.contentName &&
        dataFormModal.content.body[i].type === "SELECT"
      ) {
        if (dataFormModal.type === "LOAD_INPUT_FILE") {
          const { id, title } = payload.target;
          dataFormModal.content.body[i].value = title;
          dataFormModal.content.storage.selectedInputFile = id;
        }
        else dataFormModal.content.body[i].value = payload.target;
      }
    }
    dispatch(reducerModifierDataFormModalActionPopUp(dataFormModal));
  };
}

export function bigPathFormModalActionPopUp(payload: any) {
  return async (dispatch: any, getState: any) => {
    const dataFormModal: any = getState().formModalPopUp.dataFormModal;
    for (let i = 0; i < dataFormModal.content.body.length; i++) {
      if (
        dataFormModal.content.body[i].name === payload.contentName &&
        dataFormModal.content.body[i].type === "BIGPATH"
      ) {
        dataFormModal.content.body[i].currentValue = {
          id: payload.currentValue.id,
          title: payload.currentValue.title,
          items: payload.currentValue.items,
        };
        dataFormModal.content.body[i].parentId = payload.parentId;
      }
    }
    dispatch(reducerModifierDataFormModalActionPopUp(dataFormModal));
  };
}
