import { Dispatch } from "redux";
import AceServices, { ChatHistoryPaylodI } from "../../service/AceService";
import { ACE_ANSWER_END, ACTIVE_CITATION, ADD_CHAT_HISTORY, ADD_NEW_SESSION, FETCH_CHAT_FAILED, FETCH_CHAT_HISTORIES, FETCH_CHAT_SESSIONS, FETCH_CHAT_SUCCESS, FETCH_DATA_SOURCE, REMOVE_LATEST_ITEM, RESET_CHAT_HISTORIES, SET_CHAT_SESSIONS_PAGE, SET_SESSION_ERROR, SET_SESSION_ID, SET_SQL_CITATIONS, SET_USER_AGENT, SET_USER_FEEDBACK, UPDATE_ACE_ANSWER, UPDATE_CHAT_HISTORY, UPDATE_CLASSIFICATION, WAITING_FOR_ANSWER } from "../types/aceTypes";
import { ActiveCitationI, ChatDataT, ChatSessionsPageI } from "../reducers/aceReducers";
import { getAllChatHistories, getChatSessions, getLatestChatHistory, getSessionId } from "../../utils/reduxOutside";
import { ChatSessionI } from "../../pages/askAce/askAce.type";
import { getKnowledgeBaseUrlParams, getWordStr } from "../../utils/helpersFunction";
import { CHAT_SESSION_TITLE } from "../../utils/constant";

const service = new AceServices()

export const setLoading = (isLoading: boolean) => {
  return { type: FETCH_CHAT_HISTORIES, isLoading }
}

export const setFetching = (isFetching: boolean) => {
  return { type: FETCH_DATA_SOURCE, isFetching }
}

export const failedAction = (message: string, isLoading: boolean) => {
  return { type: FETCH_CHAT_FAILED, message, isLoading }
}

export const successAction = (chatHistories: ChatDataT[]) => {
  return { type: FETCH_CHAT_SUCCESS, chatHistories }
}

export const setSqlCitations = (data: ChatDataT) => {
  return { type: SET_SQL_CITATIONS, data }
}

export const getChatHistories = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getChatHistories()
      dispatch(successAction(response.data))
      dispatch(setLoading(false))
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const getSessionChatHistories = (page: number, size: number, sessionId: string, type?: string, isLoadMore?: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setFetching(true));
      const response = await service.getSessionChatHistories(page, size, sessionId)
      // inject chat history-id into sqlCitations data at index 0
      const newObject = response.data.map((item: ChatDataT) => {
        if (item.sqlCitations) return {
          ...item,
          sqlCitations: [...item.sqlCitations.slice(0, 0), {
            ...item.sqlCitations[0],
            id: item.id
          }, ...item.sqlCitations.slice(0 + 1)]
        }
        return item
      })
      dispatch(setChatSessionsPage({
        page,
        totalPage: response.total_pages
      }))
      console.log(sessionId, getSessionId())
      const currentChatHistories = getAllChatHistories();
      dispatch(successAction(page === 0 ? newObject : [...currentChatHistories.reverse(), ...newObject]))
      if (isLoadMore || type === 'PUBLIC') {
        dispatch(setFetching(false))
      } else {
        type === 'ENTERPRISE' && sessionId === getSessionId() && dispatch(setFetching(false))
      }
      return Promise.resolve(response);
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const updateChatHistory = (companyId?: string, sessionId?: string, type?: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const item = getLatestChatHistory();
      const chatHistoryLength = getAllChatHistories().length;
      const obj: ChatHistoryPaylodI = {
        question: item.question,
        answer: item.answer,
        classification: item.classification,
        ...(sessionId && { sessionId })
      }
      if (companyId) obj.companyId = companyId;
      const hasAgent = getState().aceReducer.hasAgent;
      if (!hasAgent) {
        const response = await service.createChatHistory(obj);
        dispatch(successExcerptsAction(response.data));
      }
      // update chat session title: 
      // because we use temporary title which is 'chat session initiialization' when creating chat session
      if (chatHistoryLength <= 1) {
        const title = getWordStr(item.question, 5)
        const data = await service.renameChatSession(sessionId as string, title)

        if (data.status === 200) {
          // push new session to session list instead re-fetch session list data
          dispatch(addNewChatSession({ id: sessionId as string, title }))
        }
      }
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const addChatItem = (question: string) => {
  return { type: ADD_CHAT_HISTORY, question }
}

export const updateAceAnswer = (answer: string) => {
  return { type: UPDATE_ACE_ANSWER, answer }
}

export const successExcerptsAction = (chatHistory: ChatDataT) => {
  return { type: UPDATE_CHAT_HISTORY, chatHistory }
}

export const setClassification = (classification: string) => {
  return { type: UPDATE_CLASSIFICATION, classification }
}

export const setUserFeedback = (id: string, voteType: string) => {
  return { type: SET_USER_FEEDBACK, id, voteType }
}

export const upsertUserFeedback = (id: string, voteType: string, upvote: boolean, downvote: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUserFeedback(id, voteType));
      await service.voteAnswer(id, upvote, downvote)
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const resetAction = () => {
  return { type: RESET_CHAT_HISTORIES }
}

export const resetChatHistories = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(resetAction());
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const AnswerEndHandler = () => {
  return { type: ACE_ANSWER_END }
}

export const CreateChatSession = (title: string, type: string, companyId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await service.createChatSession({
        title,
        type
      })
      const item = getLatestChatHistory();
      const obj: ChatHistoryPaylodI = {
        question: item.question,
        answer: item.answer,
        classification: item.classification,
        ...(companyId && { companyId }),
        sessionId: response.data.id
      }
      const responseChatHistory = await service.createChatHistory(obj);
      dispatch(setSessionId(response.data.id));
      dispatch(successExcerptsAction(responseChatHistory.data));
      dispatch(fetchChatSessions([...[response.data], ...getChatSessions()]));
      dispatch(setLoading(false));
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const fetchChatSessions = (chatSessions: ChatSessionI[]) => {
  return { type: FETCH_CHAT_SESSIONS, chatSessions }
}

export const setSessionId = (sessionId: string | null) => {
  return { type: SET_SESSION_ID, sessionId }
}

export const getChatSession = (type: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getChatSessions(type)
      dispatch(fetchChatSessions(response.data));
      dispatch(setLoading(false));
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const setChatSessionsPage = (data: ChatSessionsPageI) => {
  return { type: SET_CHAT_SESSIONS_PAGE, data }
}

export const setSessionError = (payload: any) => {
  return { type: SET_SESSION_ERROR, payload }
}

export const generateChatSessionId = (type: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await service.createChatSession({
        title: CHAT_SESSION_TITLE,
        type,
      });
      dispatch(setSessionId(response.data.id))
      // enable input component after generate session ID only for ACE Public;
      // because ACE Enterprise will initialize the agent and we enable the input after agent are ready to use;
      if (window.location.pathname.split('/')[2] === 'public') {
        dispatch(setLoading(false));
        dispatch(setFetching(false));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const fetchtDataSource = (dispatch: Dispatch, type: string) => {
  return new Promise(async (resolve) => {
    const urlParams = await getKnowledgeBaseUrlParams();
    dispatch(setFetching(true))
    Promise.all([
      service.getSources(urlParams),
      service.getChatSessions(type)
    ]).then(response => {
      dispatch(fetchChatSessions(response[1].data));
      resolve(response[0])
    }).catch(error => console.log(error))
      .finally(() => {
        if (type === 'PUBLIC') {
          dispatch(setFetching(false))
        }
      })
  })
}

export const getCitationByChatHistory = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await service.getCitationByHistory(id)
      dispatch(setSqlCitations(response.data))
      dispatch(setLoading(false));
    } catch (error: any) {
      console.log(error);
      dispatch(failedAction(error, false))
    }
  }
}

export const setUserAgent = (payload: boolean) => {
  return { type: SET_USER_AGENT, payload }
}

export const setIsStreaming = (payload: boolean) => {
  return { type: WAITING_FOR_ANSWER, payload }
}

export const removeLatestChatItem = () => {
  return { type: REMOVE_LATEST_ITEM }
}

export const addNewChatSession = (chatSession: ChatSessionI) => {
  return { type: ADD_NEW_SESSION, chatSession }
}

export const setActiveCitation = (payload: ActiveCitationI | null) => {
  return { type: ACTIVE_CITATION, payload }
}