//TODO: determine which function could be here
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IExpandTable } from './expand-table.type';

import images from '../../../assets';
import ComposerToolkit from '../../../components/molecules/composer/composerToolkit';
import {
  FormulaTable,
  ToolbarOptions
} from '../../../components/molecules/FormulaTable';
import {
  TableBox,
  TableBoxWrapper,
  TableContentSection,
  TableLeftContent,
  TableFormula,
  TableLeftFormula,
  TableRightFormula,
  TableMain,
  TableRightContent,
  TableButtonSection
} from '../../../pageElements/composer';
import { ReduxState } from '../../../store';
import { expandTableDefaultCellStyle } from '../../../utils/constant';
import { findComponent } from '../../../utils/helpersFunction';
import {
  exportPropertiesActionComposer,
  reducerModifierActivePageActionComposer
} from '../../../store/composer/composerPage/composerActions';

export const ExpandTable = ({
  open,
  selectedCell,
  expandTableContainerRef,
  expandTableRef,
  dataProps,
  handlerExpandTableContextMenu,
  handlerExpandTableAfterChanges,
  handlerExpandTableOnChange,
  handlerExpandTableCellSelection,
  handlerExpandTableColumnResize,
  handlerExpandTableToolbar,
  register,
  setValue,
  getValues,
  handlerExpandTableButtonTable,
  dataBodyOfCalculationCloned,
  setDataBodyOfCalculationCloned
}: IExpandTable) => {
  const [dataVariableManager, setDataVariableManager] = useState<any>([]);
  const [editingCell, setEditingCell] = useState<any>(null);
  const [formulaTableSize, setFormulaTableSize] = useState({
    width: 1004,
    height: 512
  });

  const dispatch = useDispatch();

  const { dataBodyOfCalculation, dataPages } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  // VARIABLE MANAGER EXPAND TABLE FUNCTION

  const navigateClick = (id: string | JSX.Element | JSX.Element[]) => {
    handlerExpandTableButtonTable('SAVE_AND_CLOSE');

    const component: { [key: string]: any } = findComponent(id);
    dispatch(reducerModifierActivePageActionComposer(component.activatedPage));
    dispatch(exportPropertiesActionComposer(component));

    const newCloned: { [key: string]: any }[][] =
      dataBodyOfCalculationCloned.map((dataPage: any) => {
        const newPageCloned: { [key: string]: any }[] = dataPage.map(
          (data: any) => {
            return {
              ...data,
              isActive: false
            };
          }
        );
        return newPageCloned;
      });

    setDataBodyOfCalculationCloned(newCloned);
  };

  const setCellCaretPosition = (type: string, variable: string) => {
    if (type === 'expandTable') {
      expandTableRef.current.setCellValue(variable, editingCell);
    }
  };

  // VIEWPORT EXPAND TABLE FUNCTION

  const handleElementResized = () => {
    if (
      expandTableContainerRef?.current &&
      expandTableContainerRef?.current?.offsetHeight !== formulaTableSize.height
    ) {
      setFormulaTableSize({
        ...formulaTableSize,
        height: expandTableContainerRef.current.offsetHeight
      });
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  const wholeTableClicked = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setEditingCell(null);
  };

  useEffect(() => {
    if (expandTableContainerRef?.current !== null) {
      resizeObserver.observe(expandTableContainerRef.current);
    }
    if (!expandTableContainerRef?.current)
      return function cleanup() {
        resizeObserver.disconnect();
      };
  }, [expandTableContainerRef?.current]);

  useEffect(() => {
    const tempDataVariableManager: {
      id: string;
      type: string;
      description: string;
      variable: string;
      notation: string;
      unit: string;
      inputValue: string | number;
      pageIndex: number;
    }[] = [];
    dataBodyOfCalculation.forEach(
      (dataPage: { [key: string]: any }[], indexPage: number) => {
        dataPage.forEach((data: { [key: string]: any }) => {
          const isTable: boolean = data.type === 'TABLE';
          const tableIdentity = data?.tableName?.replace(/\s/g, '') + '!';
          const componentTypeCheck: boolean =
            data.type === 'NUMERICAL_INPUT' ||
            data.type === 'TEXT_INPUT' ||
            data.type === 'DROPDOWN_INPUT' ||
            data.type === 'RADIO_BUTTON_INPUT' ||
            data.type === 'FORMULA' ||
            data.type === 'TABLE';
          const componentTruthyCheck: boolean =
            Boolean(data.description) &&
            Boolean(data.variable) &&
            Boolean(data.notation);
          if (componentTypeCheck && componentTruthyCheck || isTable) {
            tempDataVariableManager.push({
              id: data.id,
              type: data.type,
              description: data.description,
              variable: isTable ? tableIdentity : data.variable,
              notation: isTable ? data.tableName : data.notation,
              unit: data.unit ?? null,
              inputValue:
                data.type === 'FORMULA' && data.resultHyperFormula
                  ? data.resultHyperFormula
                  : data.type !== 'FORMULA' && data.inputValue
                    ? data.inputValue
                    : 0,
              pageIndex: dataPages[indexPage]
            });
          }
        });
      }
    );

    setDataVariableManager(tempDataVariableManager);
  }, [JSON.stringify(dataBodyOfCalculation)]);

  useEffect(() => {
    if (open) {
      sessionStorage.setItem(`expandTableCellProperties_${dataProps?.idComponent}`, JSON.stringify(dataProps.format?.cells));
    } else {
      localStorage.removeItem(`expandTableCellProperties_${dataProps?.idComponent}`);
    }
  }, [open]);

  const onCloseExpandTable = () => {
    const currentCellProperties = JSON.parse(sessionStorage.getItem(`expandTableCellProperties_${dataProps?.idComponent}`) as string) ?? {};
    const oldCellProperties: Array<string> = [];
    for (const [key] of Object.entries(currentCellProperties)) {
      oldCellProperties.push(key);
    }
    const newCellProperties: Array<string> = [];
    for (const [key] of Object.entries(dataProps.format?.cells ?? {})) {
      newCellProperties.push(key);
    }
    const changedCell = newCellProperties.filter(function (el) {
      return oldCellProperties.indexOf(el) < 0;
    });
    for (const [key, value] of Object.entries(currentCellProperties)) {
      expandTableRef.current.setCellProperties(key, value);
    }
    Array.isArray(changedCell) && changedCell.forEach((cellName: string) => {
      expandTableRef.current.setCellProperties(cellName, null);
    });
    handlerExpandTableToolbar(
      'TABLE_TOOLBAR_NUMBER_FORMAT',
      { cellProperties: currentCellProperties }
    );
    setTimeout(() => {
      handlerExpandTableButtonTable('CANCEL');
    }, 300);
  };

  return (
    <Modal open={open} disableEscapeKeyDown={true}>
      <TableBox onClick={(e: React.SyntheticEvent) => wholeTableClicked(e)}>
        <TableBoxWrapper>
          <TableContentSection>
            <TableLeftContent>
              <TableFormula>
                <TableLeftFormula>
                  <p>{selectedCell}</p>
                </TableLeftFormula>
                <TableRightFormula>
                  <img
                    src={images.xButtonTableComposer}
                    width={16}
                    alt="button-x-table"
                  />
                  <img
                    src={images.checkButtonTableComposer}
                    width={16}
                    alt="button-x=check-table"
                  />
                  <img
                    src={images.fxButtonTableComposer}
                    width={16}
                    alt="button-fx-table"
                  />
                  <div className="tableFormula">
                    <input type="text" />
                  </div>
                </TableRightFormula>
              </TableFormula>
              <TableMain
                ref={expandTableContainerRef}
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
              >
                <FormulaTable
                  size={formulaTableSize}
                  ref={expandTableRef}
                  overflow={true}
                  name={
                    dataProps.tableName
                      ? 'EXPAND_TABLE_' +
                      dataProps.tableName.split(' ').join('')
                      : 'EXPAND_TABLE'
                  }
                  data={dataProps.tableBody ? dataProps.tableBody : []}
                  stickyColumn={dataProps?.freeze?.column}
                  stickyRow={dataProps?.freeze?.row}
                  columnSetting={
                    dataProps.tableHeader ? dataProps.tableHeader : []
                  }
                  columnResize={true}
                  dimensions={[
                    dataProps.tableBody ? dataProps.tableBody[0].length : 0,
                    dataProps.tableBody ? dataProps.tableBody.length : 0
                  ]}
                  selectedCellRange={{
                    row: { start: 0, end: 0 },
                    column: { start: 0, end: 0 }
                  }}
                  cellStyle={expandTableDefaultCellStyle}
                  displayedContent={dataProps?.displayedContent}
                  contextMenu={(worksheet: any) => {
                    const newItems: any[] =
                      handlerExpandTableContextMenu(worksheet);
                    return newItems;
                  }}
                  onAfterChanges={(
                    worksheet: any,
                    updatedCells: Array<any>
                  ) => {
                    handlerExpandTableAfterChanges(worksheet, updatedCells);
                  }}
                  onValueChange={(
                    worksheet: Record<string, unknown>,
                    cell: any,
                    col: number,
                    row: number,
                    newValue: any,
                    oldValue: any
                  ) => {
                    handlerExpandTableOnChange({
                      worksheet,
                      cell,
                      col,
                      row,
                      newValue,
                      oldValue
                    });
                  }}
                  onCellRangeSelection={(worksheet: any) => {
                    handlerExpandTableCellSelection(worksheet.getSelected());
                  }}
                  onColumnResize={(
                    worksheet: any,
                    column: any,
                    width: any,
                    oldWidth: any
                  ) => {
                    handlerExpandTableColumnResize({
                      worksheet,
                      columnResized: column,
                      newWidth: width,
                      oldWidth: oldWidth
                    });
                  }}
                  onEditing={(
                    sheet: any,
                    cell: any,
                    value: string,
                    cursor: number,
                    selectedPosition: any
                  ) => {
                    setEditingCell({
                      sheet,
                      cell,
                      value,
                      cursor,
                      selectedPosition
                    });
                  }}
                  clearEditingCell={() => {
                    setEditingCell(null);
                  }}
                  toolbar={[

                    {
                      item: ToolbarOptions.DecreaseDecimal,
                      action: (cellProperties: { [key: string]: unknown }) => {
                        handlerExpandTableToolbar(
                          'TABLE_TOOLBAR_DECREASE_DECIMAL',
                          { cellProperties }
                        );
                      },
                      payload: { value: dataProps?.format?.cells }
                    },
                    {
                      item: ToolbarOptions.IncreaseDecimal,
                      action: (cellProperties: { [key: string]: unknown }) => {
                        handlerExpandTableToolbar(
                          'TABLE_TOOLBAR_INCREASE_DECIMALT',
                          { cellProperties }
                        );
                      },
                      payload: { value: dataProps?.format?.cells }
                    },

                    {
                      item: ToolbarOptions.TextAlignment,
                      action: (
                        selectedCells: Array<any>,
                        alignment: string
                      ) => {
                        handlerExpandTableToolbar('TABLE_TOOLBAR_ALIGNMENT', {
                          selectedCells,
                          alignment
                        });
                      }
                    },
                    {
                      item: ToolbarOptions.FreezeRow,
                      action: (numberOfRow: number) => {
                        handlerExpandTableToolbar('TABLE_TOOLBAR_FREEZE_ROW', {
                          numberOfRow
                        });
                      },
                      payload: { value: dataProps?.freeze?.row }
                    },
                    {
                      item: ToolbarOptions.FreezeColumn,
                      action: (numberOfColumn: number) => {
                        handlerExpandTableToolbar(
                          'TABLE_TOOLBAR_FREEZE_COLUMN',
                          { numberOfColumn }
                        );
                      },
                      payload: { value: dataProps?.freeze?.column }
                    },
                    {
                      item: ToolbarOptions.Currency,
                      action: (cellProperties: { [key: string]: unknown }) => {
                        handlerExpandTableToolbar(
                          'TABLE_TOOLBAR_NUMBER_FORMAT',
                          { cellProperties }
                        );
                      },
                      payload: { value: dataProps?.format?.cells }
                    },
                    {
                      item: ToolbarOptions.NumberFormat,
                      action: (cellProperties: { [key: string]: unknown }) => {
                        handlerExpandTableToolbar(
                          'TABLE_TOOLBAR_NUMBER_FORMAT',
                          { cellProperties }
                        );
                      },
                      payload: { value: dataProps?.format?.cells }
                    },

                  ]}
                  cells={dataProps?.format?.cells}
                />
              </TableMain>
            </TableLeftContent>
            <TableRightContent>
              <ComposerToolkit.VariableManager
                dataVariableManager={dataVariableManager}
                expandTableCellEditing={editingCell}
                register={register}
                id="searchVariableManagerTable"
                setValue={setValue}
                getValues={getValues}
                tooltip={true}
                navigating={true}
                itemClick={(type: string, variable: string) =>
                  setCellCaretPosition(type, variable)
                }
                navigateClick={(id: string | JSX.Element | JSX.Element[]) =>
                  navigateClick(id)
                }
              />
              <ComposerToolkit.TableLegend />
            </TableRightContent>
          </TableContentSection>
          <TableButtonSection>
            <div
              className="closeTable"
              onClick={onCloseExpandTable}
            >
              <p>CANCEL</p>
            </div>
            <div
              className="saveAndCloseTable"
              onClick={() => handlerExpandTableButtonTable('SAVE_AND_CLOSE')}
            >
              <p>SAVE & CLOSE</p>
            </div>
          </TableButtonSection>
        </TableBoxWrapper>
      </TableBox>
    </Modal>
  );
};
