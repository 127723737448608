import { ComponentDimensionsI, PrintFormationI, TableDimensionI } from "./print.types";

import { findLargest } from "../loggerRefactor/utils";
export interface TableHeaderI {
  width: number;
  colIdx: number;
}

export interface GeneratedTableHeaderI {
  width: number;
  type: string;
  title: string;
}

export interface FreezeTableI {
  row: number;
  column: number;
}


export function splitTableByColumn(table: any[], column: any[], indexRows: any[], freezeConfig: FreezeTableI) {

  const tableWidth = table.map((item: any, idx: number) => {
    return item.map((el: any, subIdx: number) => {
      const width = column[subIdx]?.width ?? 100;
      return {
        ...el,
        width,
        ...(el.type === 'header' && { title: String.fromCharCode(64 + (subIdx + 1)) })
      };
    });
  });

  const arrWidth: any = tableWidth[0].map((item: any) => item.width);
  const max = 500;

  const freezeColumnWidth = freezeConfig.column * 100;

  //generate table formation with dinamic max width / according freeze column exist or not
  const splitTableColumnFormation = generateSplitColumnFormation(arrWidth, max, freezeColumnWidth);

  const groupingTableSplit = Object.values(
    splitTableColumnFormation.reduce((acc: any, current: any) => {
      acc[current.page] = acc[current.page] ?? [];
      acc[current.page].push(current.index);
      return acc;
    }, {})
  );

  //insert table formation with data
  const tableSplit = groupingTableSplit?.map((item: any) => {
    return tableWidth?.map((el: any) => {
      const start = item[0];
      const end = item[0] + item.length;
      return el.slice(start, end);
    });
  });

  // add current table formation with freeze column / or not
  const tableSplitWithFreeze = tableSplit.map((item: any, idx: number) => {
    if (idx === 0) {
      return item;
    } else {
      if (freezeConfig.column > 0) {
        return item.map((el: any, subIdx: number) => {
          const freezeColumn = tableSplit[0][subIdx].slice(0, freezeConfig.column);
          return [...freezeColumn, ...el];
        });
      } else {
        return item;
      }

    }
  });

  return tableSplitWithFreeze.map((item: any) => {
    const columnIndex: Array<number> = [];
    const rowIndex: Array<number> = [];
    item.forEach((el: any) => columnIndex.push(el.length));
    rowIndex.push(item.length);
    return {
      data: item,
      columnStart: 0,
      columnEnd: findLargest(columnIndex),
      rowStart: 0,
      rowEnd: findLargest(rowIndex),
      splitByColumn: true,
      splitByRow: false,
      headers: item[0].map((el: any) => {
        return {
          width: el.width,
          type: 'text',
          title: el.title
        };
      }),
      indexRows: indexRows
    };
  });

}

export const joinComponentDimension = (component: Array<ComponentDimensionsI | TableDimensionI>, table: Array<TableDimensionI>) => {
  const newTable: Array<ComponentDimensionsI> = table.map((item: TableDimensionI) => {
    const findComponent: any = component.find((a: any) => a.columnId === item.columnId);
    return {
      ...item,
      width: item.element?.offsetWidth,
      index: findComponent.index,
      type: 'TABLE',
      height: item.height
    };
  });

  const addTableSPlitToComponent: Array<ComponentDimensionsI> = component.map((comp: any) => {
    const findtable: Array<ComponentDimensionsI> = newTable.filter((b: ComponentDimensionsI) => b.columnId === comp.columnId);
    return findtable.length ? findtable : comp;
  });
  const finalComponentData: Array<ComponentDimensionsI> = [];
  addTableSPlitToComponent.forEach((b: any) => {
    if (Array.isArray(b)) {
      b.forEach((c: ComponentDimensionsI) => finalComponentData.push({ ...c }));
    } else {
      finalComponentData.push({ ...b });
    }
  });
  return finalComponentData;
};


export function isEven(n: number) {
  return n % 2 == 0;
}

interface DisplayedContentI {
  row: { start: number; end: number };
  column: { start: number; end: number };
}

export const cellsFilter = (properties: any) => {
  const { displayedContent, cells } = properties;
  const newCells: Array<any> = [];
  cells?.forEach((cell: any) => {
    if (cell.colIdx <= displayedContent.column.end && (cell.rowIdx <= displayedContent.row.end || cell?.isAdded)) {
      newCells.push(cell);
    }
  });
  return {
    ...properties,
    cells: newCells
  };
};

export const generatePrintFormation = (arr: Array<ComponentDimensionsI>) => {
  const formation: PrintFormationI[] = [];
  const pageHeight = 830;
  const cellHeight = 26.5;
  arr.forEach(a => {
    const isTable = a.type === 'TABLE';
    if (!formation.length) {
      formation.push({
        ...a,
        page: 1,
        tableRows: isTable ? (a.height - 30) / cellHeight : null,
      });
    } else {
      const lastPage = formation[formation.length - 1].page;
      const nextPage = formation[formation.length - 1].page + 1;
      const currentHeight = formation.filter(item => item.page === lastPage).reduce((n, { height }) => n + height, 0);
      const isFit = currentHeight + a.height < pageHeight;
      if (isTable) {
        if (isFit) {
          formation.push({
            ...a,
            page: lastPage,
            tableRows: (a.height - 30) / cellHeight
          });
        } else {
          const firstTable: PrintFormationI = {
            ...a,
            height: pageHeight - currentHeight,
            page: lastPage,
            tableRows: (pageHeight - currentHeight - 30) / cellHeight
          };
          const secondTable: PrintFormationI = {
            ...a,
            height: a.height - firstTable.height + 30,
            page: nextPage,
            tableRows: (a.height - firstTable.height) / cellHeight
          };
          formation.push(firstTable);
          formation.push(secondTable);

        }
      } else {
        if (isFit) {
          formation.push({
            ...a,
            page: lastPage,
            tableRows: null,
          });
        } else {
          formation.push({
            ...a,
            page: nextPage,
            tableRows: null,
          });
        }
      }
    }
  });
  return formation;
};

export interface SplitColumnFormationI {
  index: number;
  width: number;
  page: number;
}

//alternative chunks function, generate table formation based on the max width
export const generateSplitColumnFormation = (arr: number[], maxWidth: number, freezeWidth: number) => {
  const formation: Array<SplitColumnFormationI> = [];
  arr.forEach((a, i) => {
    if (!formation.length) {
      formation.push({
        index: i,
        width: a,
        page: 1
      });

    } else {
      const lastPage = formation[formation.length - 1].page;
      const nextPage = formation[formation.length - 1].page + 1;
      const currentWidth = formation.filter(item => item.page === lastPage).reduce((n, { width }) => n + width, 0);
      const isFit = currentWidth + a <= maxWidth;
      const isFitFreeze = currentWidth + a <= maxWidth - freezeWidth;
      if (lastPage === 1) {
        if (isFit) {
          formation.push({
            index: i,
            width: a,
            page: lastPage
          });
        }
        else {
          formation.push({
            index: i,
            width: a,
            page: nextPage
          });
        }
      } else {
        if (isFitFreeze) {
          formation.push({
            index: i,
            width: a,
            page: lastPage
          });
        }
        else {
          formation.push({
            index: i,
            width: a,
            page: nextPage
          });
        }
      }

    }
  }
  );
  return formation;

};