import { ComponentDimensionsI, TableDimensionI } from './print.types';
import { generatePrintFormation, joinComponentDimension } from './utils';
import {
  setReportChanges,
  setReportSaveFormat,
  updateReportName
} from '../../store/types/reportTypes';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import HelperButton from '../../components/molecules/HelperButton';
import { LoadingSpinner } from '../../components/atoms/loading';
import { PageContainer } from './styles';
import PageHeader from './components/PageHeader';
import PrintForm from './components/Form';
import { ReduxState } from '../../store';
import ReportInfo from './components/ReportInfo';
import ReportSetting from './components/ReportSetting';
import SidePanel from './components/SidePanel';
import Workpages from './components/Workpages';
import { changePositionActionCircleatorBreadcrumb } from '../../store/breadCrumb/circleator/circleatorAction';
import { fetchCalculationReportList } from '../../store/actions/reportAction';
import { formModalSetupActionPopUp } from '../../store/appState/popUp/formModal/formModalAction';
import { getSymbols } from '../../store/appState/utils/action';
import images from '../../assets';
import { printViewHOC } from '../../components/organisms/hoc/printViewHOC';

const PrintView = (props: any) => {
  const { fetchReport, report, isLoadingSubmit, reportData } = props;
  const { currentReport, isLoading, loadingReport } = report;
  const { id } = props.match.params;
  const [printPages, setPrintPages] = useState<Array<any>>([]);
  const [reportPages, setReportPages] = useState<Array<any>>([]);
  const [reportFormat, setReportFormat] = useState<Array<any>>([]);
  const [summaryReport, setSummaryReport] = useState<Array<any>>([]);
  const [reportName, setReportName] = useState<string>(currentReport.name);
  const dispatch = useDispatch();
  const history = useHistory();
  const latexRefs = useRef<Array<any>>([]);
  latexRefs.current = [];
  const [latexWrapper, setLatexWrapper] = useState<Array<any>>([]);
  const { calculationId, inputFileId } = useParams<{
    calculationId: string;
    inputFileId: string;
  }>();

  const ComponentDimensionRef = useRef<Array<ComponentDimensionsI>>([]);
  ComponentDimensionRef.current = [];

  const TableDimensionRef = useRef<Array<TableDimensionI>>([]);
  TableDimensionRef.current = [];

  const setDimension = (el: ComponentDimensionsI) => {
    if (el.element && !ComponentDimensionRef.current.find((item: any) => item.columnId === el.columnId)) {
      ComponentDimensionRef.current.push({
        ...el,
        height: el.element.offsetHeight,
        width: el.element.offsetWidth
      });
    }
  };

  const handleSetTableDimension = (data: TableDimensionI) => {

    if (data.element && !TableDimensionRef.current.find((item: TableDimensionI) => item.key === data.key)) {
      TableDimensionRef.current.push(data);
    }
  };

  const [componentDimension, setComponentDimension] = useState<Array<ComponentDimensionsI>>([]);
  const [tableDimension, setTableDimension] = useState<Array<TableDimensionI>>([]);
  useEffect(() => {
    componentDimension.length !== ComponentDimensionRef.current.length && setComponentDimension(ComponentDimensionRef.current);
    tableDimension.length !== TableDimensionRef.current.length && setTableDimension(TableDimensionRef.current);
  }, [ComponentDimensionRef.current, TableDimensionRef.current]);

  useEffect(() => {
    dispatch(changePositionActionCircleatorBreadcrumb('print'));
    const unsubscribe = loadLastReport();
    return () => unsubscribe;
  }, [reportData, currentReport]);

  const loadLastReport = () => {
    if (
      reportData &&
      reportData?.currentReport?.id &&
      currentReport?.inputFile?.pages.length > 0
    ) {
      handleLatestSavedData(
        reportData?.currentReport.format,
        reportData?.executiveSummary
      );
    }
    setReportName(currentReport.title);
  };

  useEffect(() => {
    const unsubscribe = handleFetchResources(calculationId);
    return unsubscribe;
  }, [calculationId, loadingReport, currentReport]);

  const handleFetchResources = (calculationId: string) => {
    try {
      dispatch(fetchCalculationReportList(calculationId));
    } catch (error: any) {
      console.error(error);
    }
  };

  const { userLimit } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { subscription } = useSelector((state: ReduxState) => state.login);

  const pageElements: any = currentReport?.inputFile?.pages
    .map((a: any, i: number) => {
      return {
        pageTitle: a.pageTitle,
        pageAlias: a.pageTitle + ' ' + i,
        components: a.components,
        isHidden: a.isHidden
      };
    })
    .filter((b: any) => !b.isHidden)
    .filter(
      (page: any, i: number) =>
        printPages.includes(page.pageAlias) ||
        page.pageAlias === 'Executive Summary'
    );

  useEffect(() => {
    handleUpdateReportName();
  }, [reportName]);

  const handleUpdateReportName = () => {
    dispatch(updateReportName(reportName));
  };

  const getTableNum = () => {
    let num = 1;
    for (let i = 0; i < currentReport?.inputFile?.pages.length; i++) {
      currentReport?.inputFile?.pages[i].components.forEach((el: any) => {
        if (el.type === 'TABLE') {
          el.properties.tableNum = `Table ${num}`;
          num++;
        }
      });
    }
  };

  const handleLatestSavedData = async (data: any, execSummary: any[]) => {
    const { latex, summary } = data;

    getTableNum();
    setSummaryReport([
      {
        pageAlias: 'Executive Summary',
        pageTitle: 'Executive Summary',
        rows: execSummary
      }
    ]);

    const newPages: any = await currentReport?.inputFile?.pages
      .map((a: any, i: number) => {
        return {
          pageTitle: a.pageTitle,
          pageAlias: a.pageTitle + ' ' + i,
          components: a.components,
          isHidden: a.isHidden
        };
      })
      .filter((b: any) => !b.isHidden);

    const savedPage = await newPages
      .map(function (item: any, i: number) {
        return item.pageAlias;
      })
      .filter((_: any, i: number) => data.pages.includes(i));

    const format = [];
    if (summary) format.push('Executive Summary');
    if (latex) format.push('Latex Formulas');

    setReportPages(newPages);
    setPrintPages(savedPage);
    setReportFormat(format);
  };

  useEffect(() => {
    /*
      Get Symbol for Latex
    */
    dispatch(getSymbols());
    const unsubscribe = fetchReport(id);
    setLatexWrapper(latexRefs.current);
    return unsubscribe;
  }, [id]);

  useEffect(() => {
    dispatch(
      setReportSaveFormat({
        pages: printPages.map(item => {
          return parseInt(item.slice(-1));
        }),
        summary: reportFormat.includes('Executive Summary'),
        latex: reportFormat.includes('Latex Formulas'),
        title: reportName
      })
    );

    if (
      currentReport?.format?.latex !== reportFormat.includes('Latex Formulas')
        ? true
        : false ||
          currentReport?.format?.summary !==
          reportFormat.includes('Executive Summary')
          ? true
          : false || currentReport?.title !== reportName
            ? true
            : false ||
            printPages
              ?.map(item => {
                return parseInt(item.slice(-1));
              })
              ?.sort()
              .join(',') !== currentReport?.format?.pages.sort().join(',')
    ) {
      dispatch(setReportChanges(true));
    } else {
      dispatch(setReportChanges(false));
    }
  }, [printPages, reportFormat, reportName]);

  const handleSetPagesPrint = (e: any) => {
    setPrintPages(e);
  };

  const handleSetReportFormat = (e: any) => {
    setReportFormat(e);
  };

  const saveReport = async () => {
    try {
      dispatch(
        formModalSetupActionPopUp('SAVE_REPORT_PRINT_VIEW', {
          currentReport,
          title: reportName,
          isActive: true,
          format: {
            pages: printPages.map(item => {
              return parseInt(item.slice(-1));
            }),
            summary: reportFormat.includes('Executive Summary'),
            latex: reportFormat.includes('Latex Formulas')
          }
        })
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const leaveButton = async () => {
    try {
      dispatch(
        formModalSetupActionPopUp('LEAVE_REPORT_PRINT_VIEW', {
          calculationId,
          inputFileId,
          history,
          title: reportName
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const addLatexRef = (el: any) => {
    if (el.element && !latexRefs.current.includes(el)) {
      latexRefs.current.push({
        ...el,
        multiLine: el.element.offsetWidth >= 520 ? true : false
      });
    }
  };

  return (
    <>
      {isLoading || !pageElements ? (
        <LoadingSpinner />
      ) : (
        <PageContainer>
          <SidePanel
            sticky={false}
            top={
              <ReportInfo
                info={currentReport}
                image={images}
                leaveButton={leaveButton}
              />
            }
            bottom={
              <ReportSetting
                info={currentReport}
                isLoadingSubmit={isLoadingSubmit}
                pages={reportPages}
                printPages={printPages}
                reportFormat={reportFormat}
                handleSetPagesPrint={handleSetPagesPrint}
                handleSetReportFormat={handleSetReportFormat}
                saveReportHandler={saveReport}
                reportName={reportName}
                setReportName={setReportName}
                userLimit={userLimit}
                subscription={subscription}
                componentDimensionAll={joinComponentDimension(componentDimension, tableDimension)}
              />
            }
          />
          <Workpages
            pageHeader={<PageHeader data={currentReport} />}
            pageContent={
              <PrintForm
                data={pageElements}
                showLatex={Boolean(reportFormat.includes('Latex Formulas'))}
                setComponentDimension={setDimension}
                setTableDimension={handleSetTableDimension}
              />
            }
          />
        </PageContainer>
      )}
      <HelperButton />
    </>
  );
};

export default printViewHOC(PrintView);
