import {
  SourceListItem
} from '../../styles';
import { Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import Icon from '../../../../components/atoms/Icon';
import { useLocation } from 'react-router-dom';
import Text from '../../../../components/atoms/Text';
import { getIsConsents, getUserType } from '../../../../utils/storage';
import { HCSS_URL, TEXT_ACE_ENTERPRISE } from '../../../../utils/constant';
import { fetchtDataSource } from '../../../../store/actions/aceActions';
import { useDispatch } from 'react-redux';
import StyledButton from '../../../../components/atoms/Button';
import { theme } from '../../../../utils/theme';
import { useAppSelector } from '../../../../hooks/hooks';
import { AceStateI } from '../../../../store/reducers/aceReducers';

const buttonStyles = {
  textAlign: 'center', width: '100%', textTransform: 'none',
  color: `${theme.palette.primary.main} !important`
}

const titleStyles = { paddingRight: 2 }

type TAceSource = {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  url?: string;
  type?: string;
};

export const SourceDisplay = () => {
  const location = useLocation();
  const currentPage = location.pathname.split('/')[2];
  const [aceSource, setAceSource] = useState<TAceSource[]>([]);
  const dispatch = useDispatch();
  const { activeCitation }: AceStateI = useAppSelector(state => state.aceReducer);
  const userConsent: Record<string, unknown> = getIsConsents();
  const isConsent = userConsent.isConsent;

  const getDataSource = useCallback(async () => {
    try {
      const res: any = await fetchtDataSource(dispatch, currentPage.toUpperCase());
      setAceSource(res.data);
    } catch (e) {
      console.error(e);
    }
  }, [currentPage]);

  useEffect(() => {

    if (currentPage === 'enterprise' && getUserType() !== TEXT_ACE_ENTERPRISE) return;
    if (isConsent) {
      getDataSource();
    }
  }, [location, isConsent]);

  const handleOpenSource = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Typography variant="h5" paragraph={true}>
        Source
      </Typography>
      <div id="content">
        {aceSource.length ? aceSource.map((item: TAceSource) => {
          return (
            <SourceListItem key={item.id} selected={activeCitation?.selected === item.id}>
              {!item.type ?
                <>
                  <div id="title">
                    <Icon icon='typeBook' />
                    <Tooltip title={item.title}>
                      <Typography variant="body2" textOverflow={'ellipsis'} style={{ cursor: 'pointer' }}>
                        {item.title.length > 16 ? item.title.substring(0, 15) + '...' : item.title}
                      </Typography>
                    </Tooltip>
                  </div>
                  <Icon icon="externalLink" onClick={() => handleOpenSource(item.url)} />
                </>
                :
                <StyledButton variant='outlined' size='small' sx={buttonStyles} onClick={() => window.open(HCSS_URL, "_blank")}>
                  <Typography variant='h5' color={theme.palette.primary.main} sx={titleStyles}>{item.title}</Typography>
                  <Icon icon="externalLink" onClick={() => handleOpenSource(item.url)} color={theme.palette.primary.main} />
                </StyledButton>
              }
            </SourceListItem>
          );
        }) : <Text variant='body2' color="#A3B3C2" style={{ margin: "12px 16px" }}>No source</Text>}
      </div>
    </>
  );
};
